import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#283A44',
                secondary: '#C5BBB3',
                accent: '#FF4500',
                error: '#b71c1c',
                test2: "#0c37b5"
                
            },
            dark: {
                primary: colors.blue.lighten3,
            },
        },
    },
});
