<template>
    <div id="session-internal">

        <template>
            <div class="text-center">

            </div>
        </template>

        <v-card class="a">
            <v-progress-linear indeterminate :active="communicationLoading"
                               color="#FF4500" style="margin-bottom:1px;"></v-progress-linear>


            <v-card class="mb-1" color="primary" dark>
                <v-card-title class="justify-center py-0">{{ sessionData.name }}</v-card-title>
                <v-card-subtitle class="text-center pt-2 pb-0">{{ sessionData.campaign }}</v-card-subtitle>


            </v-card>

            <!---Button Palette-->
            <v-card class="mb-1" color="primary" dark> 
                <v-container fluid>
                    <v-row justify="space-between" dense cols="auto">



                        <v-col v-if="IsGM">
                            <v-btn color="#FBC02D"
                                   width="100%"
                                   :height="buttonHeight"
                                   @click="buttonStart" light>
                                Start
                            </v-btn>
                        </v-col>

                        <v-col v-if="IsGM">
                            <v-btn color="#FBC02D"
                                   width="100%"
                                   :height="buttonHeight"
                                   @click="buttonEnd" light>
                                End
                            </v-btn>
                        </v-col>

                        <v-col v-if="IsGM">
                            <v-btn color="#FBC02D"
                                   width="100%"
                                   :height="buttonHeight"
                                   @click="buttonGo" light>
                                Go
                            </v-btn>
                        </v-col>

                        <v-col>
                            <v-btn color="#43A047"
                                   width="100%"
                                   :height="buttonHeight"
                                   style="color:black"
                                   @click="buttonConfirm" :disabled="!turnEnabled">
                                Confirm
                            </v-btn>
                        </v-col>

                        <!--ATK Button Modal-->
                        <v-col>
                            <v-dialog v-model="ATKDialog"
                                      :max-width="dialogWidth"
                                      persistent>

                                <v-card style="background-color: #C5BBB3">
                                    <v-card-title class="justify-center py-0"
                                                  style="background-color: #283a44; color: white">
                                        ATK/DFND
                                    </v-card-title>

                                    <v-divider></v-divider>

                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12"
                                                   class="py-0">
                                                <!---Weapon-->
                                                <v-row v-if="ATKDialog_ATKDFNStatus">
                                                    <v-col cols="12" class="py-0">
                                                        <v-container fluid>
                                                            <v-row >
                                                                <v-col cols="12" class="pb-0">
                                                                    <v-card-title class="justify-center pa-0 pb-3">
                                                                        Weapon
                                                                    </v-card-title>
                                                                </v-col>
                                                            </v-row>

                                                            <!--Select Weapon-->
                                                            <v-item-group v-model="atkModalData.selectedWeapons"
                                                                          active-class="primary">
                                                                <!--Sets SelectedWeapon to current-->
                                                                <v-row justify="center">
                                                                    <v-col class="pb-0"
                                                                           cols="auto"
                                                                           v-for="item in currentcharacterLoadout.weapons"
                                                                           v-if="item.name != ''"
                                                                           :key="item.weaponID">
                                                                        <v-item v-slot="{ active, toggle }">
                                                                            <v-btn color="#FF4500"
                                                                                   :height="buttonHeight"
                                                                                   light
                                                                                   @click="selectWeapon(item); toggle();">
                                                                                {{item.name}}
                                                                            </v-btn>
                                                                        </v-item>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-item-group>
                                                        </v-container>
                                                    </v-col>
                                                </v-row>

                                                <!---Armor-->
                                                <v-row v-if="!ATKDialog_ATKDFNStatus">
                                                    <v-col cols="12" 
                                                           class="pb-0">
                                                        <v-container fluid>
                                                            <v-row>
                                                                <v-col cols="12" class="pb-0">
                                                                    <v-card-title class="justify-center pa-0 pb-3">
                                                                        Armor
                                                                    </v-card-title>
                                                                </v-col>
                                                            </v-row>

                                                            <v-item-group v-model="atkModalData.selectedArmors"
                                                                          active-class="primary">
                                                                 <v-row justify="center">
                                                                      <v-col class="pb-0"
                                                                             cols="auto"
                                                                             v-for="item in currentcharacterLoadout.armors"
                                                                             v-if="item.name != ''"
                                                                             :key="item.armorID">
                                                                           <v-item v-slot="{ active, toggle }">
                                                                                <v-btn color="#FF4500"
                                                                                       :height="buttonHeight"
                                                                                       light
                                                                                       @click="selectArmor(item); toggle();">
                                                                                     {{item.name}}
                                                                                </v-btn>
                                                                           </v-item>
                                                                      </v-col>
                                                                 </v-row>

                                                            </v-item-group>

                                                        </v-container>
                                                    </v-col>
                                                </v-row>

                                                <!---Shield-->
                                                <v-row v-if="!ATKDialog_ATKDFNStatus">
                                                    <v-col cols="12" class="pb-0">
                                                        <v-container fluid>
                                                            <v-row>
                                                                <v-col cols="12" class="pb-0">
                                                                    <v-card-title class="justify-center pa-0 pb-3">
                                                                        Shield
                                                                    </v-card-title>
                                                                </v-col>
                                                            </v-row>

                                                            <v-item-group v-model="atkModalData.selectedShields"
                                                                          active-class="primary">
                                                                 <v-row justify="center">
                                                                      <v-col class="pb-0"
                                                                             cols="auto"
                                                                             v-for="item in currentcharacterLoadout.shields"
                                                                             v-if="item.name != ''"
                                                                             :key="item.armorID">
                                                                           <v-item v-slot="{ active, toggle }">
                                                                                <v-btn color="#FF4500"
                                                                                       :height="buttonHeight"
                                                                                       light
                                                                                       @click="selectShield(item); toggle();">
                                                                                     {{item.name}}
                                                                                </v-btn>
                                                                           </v-item>
                                                                      </v-col>
                                                                 </v-row>

                                                            </v-item-group>

                                                        </v-container>
                                                    </v-col>
                                                </v-row>


                                                 <!--Miscellanious-->
                                                <v-row >
                                                    <v-col cols="12"
                                                           class="py-0">
                                                         <v-container fluid>
                                                              <v-row>
                                                                   <v-col cols="12" class="pb-0">
                                                                        <v-card-title class="justify-center pa-0 pb-3">
                                                                             Miscellaneous
                                                                        </v-card-title>
                                                                      
                                                                   </v-col>
                                                              </v-row>

                                                              <v-row justify="center"
                                                                     align="center"
                                                                     dense
                                                                     cols="auto">

                                                                  <v-col cols="12"
                                                                         md="auto"
                                                                         lg="auto"
                                                                         xl="auto"
                                                                         class="pb-0">
                                                                      Distance
                                                                      <v-text-field solo
                                                                                    dense
                                                                                    label="Distance"
                                                                                    v-model.number="atkModalData.rangedDistance"
                                                                                    single-line
                                                                                    hide-details
                                                                                    type="number">

                                                                      </v-text-field>

                                                                  </v-col>

                                                                  <v-col cols="12"
                                                                         md="auto"
                                                                         lg="auto"
                                                                         xl="auto"
                                                                         class="pb-0">
                                                                      Self Move
                                                                      <v-text-field solo
                                                                                    dense
                                                                                    label="Self Move"
                                                                                    v-model.number="atkModalData.rangedSelfMove"
                                                                                    single-line
                                                                                    hide-details
                                                                                    type="number"
                                                                                    :height="buttonHeight">

                                                                      </v-text-field>

                                                                  </v-col>

                                                                  <v-col cols="12"
                                                                         md="auto"
                                                                         lg="auto"
                                                                         xl="auto"
                                                                         class="pb-0">
                                                                      Target Move
                                                                      <v-text-field solo
                                                                                    dense
                                                                                    label="Target Move"
                                                                                    v-model.number="atkModalData.rangedTargetMove"
                                                                                    single-line
                                                                                    hide-details
                                                                                    type="number"
                                                                                    style="min-height:auto !important"
                                                                                    :height="buttonHeight">

                                                                      </v-text-field>

                                                                  </v-col>

                                                                  <!---Ranged Penalty calculated in computed data
    Also updates rangedPenalty field in atkModal as long as GetRangedPenalty is called-->
                                                                  <v-col cols="12"
                                                                         md="auto"
                                                                         lg="auto"
                                                                         xl="auto"
                                                                         class="pb-0">
                                                                      Target Factor <br />
                                                                      <!--Ranged Penalty: {{ atkModalData.rangedPenalty }} <br />-->

                                                                      <v-card style="background-color: #FFFFFF"
                                                                              width="100%">
                                                                          <select v-model.number="atkModalData.targetFactor"
                                                                                  width="100%"
                                                                                  class="pa-2"
                                                                                  style="background-color: #FFFFFF; width: 100%">
                                                                              <option disabled value="0">Target Factor</option>
                                                                              <option value="1">
                                                                                  Long Sighted
                                                                              </option>
                                                                              <option value="2">
                                                                                  Short Sighted
                                                                              </option>
                                                                              <option value="3">
                                                                                  Fast Stance
                                                                              </option>
                                                                              <option value="4">
                                                                                  Unsighted/Hip
                                                                              </option>
                                                                              <option value="5">
                                                                                  Thrown/Powers
                                                                              </option>

                                                                          </select>
                                                                      </v-card>
                                                                  </v-col>

                                                                  <!--<v-row>
        <v-col cols="4">
            <v-btn color="#FF4500"
                   :height="buttonHeight"
                   class="white--text"
                   @click="buttonATK">
                Previous ATK/DFND
            </v-btn>
        </v-col>
    </v-row>-->

                                                                  <v-col cols="12"
                                                                         md="auto"
                                                                         lg="auto"
                                                                         xl="auto"
                                                                         class="pb-0">
                                                                      Penalty

                                                                      <v-text-field solo
                                                                                    dense
                                                                                    label="Attack Penalty"
                                                                                    v-model.number="atkModalData.atkPenalty"
                                                                                    single-line
                                                                                    hide-details
                                                                                    type="number"
                                                                                    style="min-height:auto !important"
                                                                                    :height="buttonHeight">
                                                                      </v-text-field>

                                                                  </v-col>

                                                                  <v-col cols="12"
                                                                         md="auto"
                                                                         lg="auto"
                                                                         xl="auto"
                                                                         class="pb-0">
                                                                      Bonus

                                                                      <v-text-field solo
                                                                                    dense
                                                                                    label="Attack Bonus"
                                                                                    v-model.number="atkModalData.atkBonus"
                                                                                    single-line
                                                                                    hide-details
                                                                                    type="number"
                                                                                    style="min-height:auto !important"
                                                                                    :height="buttonHeight">
                                                                      </v-text-field>

                                                                  </v-col>




                                                                 


                                                                  <v-col cols="12"
                                                                         md="auto"
                                                                         lg="auto"
                                                                         xl="auto"
                                                                         class="pb-0">
                                                                      Ranged Penalty

                                                                      <v-text-field solo
                                                                                    dense
                                                                                    v-model.number="GetRangedPenalty"
                                                                                    single-line
                                                                                    hide-details
                                                                                    type="number"
                                                                                    style="min-height:auto !important"
                                                                                    :height="buttonHeight" :disabled="true">
                                                                      </v-text-field>

                                                                  </v-col>

                                                              </v-row>

                                                             

                                                         </v-container>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                    <br />
                                    <v-card-actions style="background-color: #283a44">
                                        
                                        <v-spacer></v-spacer>
                                        <v-btn color="green lighten-1" 
                                               text
                                               :height="buttonHeight"
                                               @click="ATKDFNModalConfirm" light>
                                            Confirm
                                        </v-btn>

                                        <v-btn color="red lighten-3"
                                               text
                                               :height="buttonHeight"
                                               @click="CloseATKDFNModal()"
                                               :disabled="DisableCloseATKModal">
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <!--Actual ATK Button-->
                            <v-btn color="#FF4500"
                                   width="100%"
                                   :height="buttonHeight"
                                   style="color:black"
                                   @click="buttonATK" :disabled="!turnEnabled" >
                                ATK
                            </v-btn>
                        </v-col>

                        <!--<v-col>
                            <v-btn color="#FF4500"
                                   width="100%"
                                   :height="buttonHeight"
                                   class="white--text"
                                   @click="buttonRPTATK">-->
<!--                                 -enabled for debugging, uncomment for release/demo
                                 :disabled="!turnEnabled">-->
                                <!--RPT ATK
                            </v-btn>
                        </v-col>-->

                        <v-col>
                            <v-btn color="#FF4500"
                                   width="100%"
                                   :height="buttonHeight"
                                   style="color:black"
                                   @click="buttonMove" :disabled="!turnEnabled" >
                                Move
                            </v-btn>
                        </v-col>

                        <!--<v-col>
                            <v-btn color="#FF4500"
                                   width="100%"
                                   :height="buttonHeight"
                                   class="white--text"
                                   @click="buttonDFND">
                                DFND
                            </v-btn>
                        </v-col>-->

                        <v-col>
                            <v-btn color="#FF4500"
                                   width="100%"
                                   :height="buttonHeight"
                                   style="color:black"
                                   @click="buttonHold" :disabled="!turnEnabled" >
                                Hold
                            </v-btn>
                        </v-col>

                        <v-col>
                            <v-btn color="#FF4500"
                                   width="100%"
                                   :height="buttonHeight"
                                   style="color:black"
                                   @click="buttonWait" :disabled="!turnEnabled" >
                                Wait
                            </v-btn>
                        </v-col>

                        <!--<v-col>
                            <v-btn color="#FF4500"
                                   width="100%"
                                   :height="buttonHeight"
                                   class="white--text"
                                   @click="buttonINTRV">
                                INTRV
                            </v-btn>
                        </v-col>-->

                        <!--Character in-session Edit-->
                        <v-col>
                            <v-btn color="#FF4500"
                                   width="100%"
                                   :height="buttonHeight"
                                   style="color:black"
                                   @click="buttonEdit" :disabled="!turnEnabled" >
                                Edit
                            </v-btn>
                        </v-col>

                        <v-col>
                            <v-btn color="#FF4500"
                                   width="100%"
                                   :height="buttonHeight"
                                   style="color:black"
                                   @click="LeaveSession" >
                                Leave
                            </v-btn>
                        </v-col>


                    </v-row>

                </v-container>
            </v-card>



            <!---Init Table and Chat Log-->
            <v-card>
                <v-container class="pa-0" fluid>
                    <v-row justify="start"
                           cols="auto"
                           dense>

                        <v-col cols="12"
                               md="4"
                               lg="4"
                               xl="4">
                            <!---Init Table-->

                            <v-card color="primary" dark :disabled="SelectedCharactersConfirmed">
                                <v-card-title class="justify-center py-1" >
                                    Initiative Table
                                </v-card-title>
                              
                                <v-card class="overflow-y-auto mx-4"
                                        :height="initHeight"
                                        color="primary" dark
                                        elevation="0">

                                    <v-list rounded
                                            dense
                                            class="pa-0">

                                         <v-list-item-group v-model="selectedCharacters"
                                                            class="dark-background"
                                                            multiple
                                                            active-class="red darken-2">

                                              <v-list-item v-for="item in init_records" :key="item.initID" light
                                                           color="#FFFFFF" 
                                                           class="my-2 py-0  elevation-2"
                                                           :style="{ background: usThemColor(LookupCharacterTeam(item.initID_ChrcProfileID))  }"
                                                           width="100%"
                                                           :height="initPlayerHeight"
                                                           @click="characterSelect(item.initID_ChrcProfileID);"
                                                           :disabled="OwnCharacter(item.initID_ChrcProfileID)">


                                                   <!--style="background-color: #5EB8FF"-->
                                                   <!--:style="{{ background-color: usThemColor(LookupCharacterTeam(item.initID_ChrcProfileID)) }}"-->
                                                   <!--color : {{ usThemColor(LookupCharacterTeam(item.initID_ChrcProfileID)) }}-->
                                                   {{item.initval }}

                                                   <v-divider vertical
                                                              class="mx-4">
                                                   </v-divider>

                                                   {{LookupCharacterName(item.initID_ChrcProfileID)}}
                                                   <!--{{LookupCharacterTeam(item.initID_ChrcProfileID)}}-->
                                                   <v-spacer></v-spacer>
                                              </v-list-item>
                                              
                                         </v-list-item-group>
                                    </v-list>
                                    <!--Character IDs Selected:
                                    <span v-for="item in selectedIDs">
                                        {{item}}
                                    </span>-->

                                    <!--Selected: {{selectedCharacters}}-->


                                </v-card>
                            </v-card>
                        </v-col>

                        <v-col cols="12"
                               md="8"
                               lg="8"
                               xl="8">
                            <!--Chat Log-->
                            <v-card color="primary">
                                <v-card-title class="justify-center py-1" style="color:#FFFFFF">
                                    Chat Log
                                </v-card-title>
                               
                                <v-card class="overflow-y-auto mx-4 chatbox"
                                        :height="chatHeight">


                                    <div v-for="message in chat_messages" :key="message.ChatmessageID" style="margin:5px">

                                        <v-card v-if="message.isDM"
                                                outlined
                                                class="my-1 chatmessage"
                                                width="100%"
                                                min-height="10%"
                                                color="yellow lighten-3">

                                            (<strong>{{message.fromCharacterName}}</strong>)

                                            {{message.message}}
                                            <v-spacer></v-spacer>
                                        </v-card>


                                        <v-card v-else-if="!message.isDM && message.fromCharacterName == 'System'"
                                                outlined
                                                class="my-1 chatmessage"
                                                width="100%"
                                                min-height="10%"
                                                color="blue lighten-3">

                                            <strong>{{message.message}}</strong>
                                            
                                            <v-spacer></v-spacer>
                                        </v-card>


                                        <v-card v-else-if="!message.isDM && message.fromCharacterName == 'System-Event'"
                                                outlined
                                                class="my-1 chatmessage"
                                                width="100%"
                                                min-height="10%"
                                                color="red lighten-3">


                                            <strong style="white-space:pre">{{message.message}}</strong>
                                            <v-spacer></v-spacer>
                                        </v-card>


                                        <v-card v-else
                                                outlined
                                                class="my-1 chatmessage"
                                                width="100%"
                                                min-height="10%">

                                            (<strong>{{message.fromCharacterName}}</strong>)

                                            {{message.message}}
                                            <v-spacer></v-spacer>
                                        </v-card>

                                    </div>


                                </v-card>


                                <v-form @submit.prevent="sendMessage">
                                    <v-container>
                                        <v-row style="padding:5px">
                                            <!---Dropdown character select-->
                                             <v-col cols="4"
                                                    class="pt-4">
                                                  <v-card style="background-color: #FFFFFF"
                                                          width="100%">
                                                      <select v-model="selected"
                                                              class="pa-2"
                                                              style="background-color: #ffffff; width: 100%">
                                                          <option disabled value="">Select Player</option>
                                                          <option :value="0">Everyone</option>
                                                          <option v-for="item in FilterInit(init_records)" :key="item.initID" :value="item">
                                                              {{LookupCharacterName(item.initID_ChrcProfileID)}}
                                                          </option>

                                                      </select>
                                                  </v-card>
                                             </v-col>
                                            <v-spacer></v-spacer>

                                            <!---Text input-->
                                            <v-col cols="8" class="pt-4">

                                                <v-text-field class="py-0"
                                                              v-model="message"
                                                              label="Message"
                                                              maxlength="2000"
                                                              clear-icon="mdi-close-circle"
                                                              clearable
                                                              solo
                                                              dense>



                                                    <template v-slot:append-outer>
                                                        <v-icon @click="sendMessage" dark>
                                                            mdi-send
                                                        </v-icon>
                                                    </template>

                                                </v-text-field>

                                               

                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-container>
            </v-card>

        </v-card>

        <!--Debug area-->
        <br />
        <!--<div>

            <div>Players</div>
            <ul>
                <li v-for="p in player_records">{{p.playerID}} {{LookupPlayerName(p.playerID)}}</li>
            </ul>
            <br />

            <div>Init_Records</div>
            <ul>
                <li v-for="p in init_records">{{p}}</li>
            </ul>
            <br />

            <div>Characters</div>
            <ul>
                <li v-for="p in character_records">{{p.character}}</li>
            </ul>
            <br />-->

            <!--<div>Chat messages</div>
        <ul>
            <li v-for="p in chat_messages">{{p}}</li>
        </ul>
        <br />-->



        <!--</div>-->

        <!--character edit section-->
        <!--Dialog when joining a session-->
        <v-dialog v-model="editCharData.selectCharacterDialog"
                  width="500" persistent style="max-height:70%">

            <v-card>
                <v-card-title class="text-h5 primary" style="font-weight:500; color:#FFFF">
                    Character Selection
                </v-card-title>

                <v-card-subtitle class="primary pt-2" style="color:#FFFF">
                    Please click on a character from your list below
                </v-card-subtitle>

                <v-card-text>
                    <v-list>
                        <v-list-item-group v-model="editCharData.cur_CharacterID">                         
                            <v-list-item v-for="(item, i) in Filtered_EditCharacterData"
                                         :key="item.characterID" :value="item.characterID" style="margin-bottom:2px">

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-text="item.groupname"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="primary pt-2" style="color:#FFFF">
                    <v-spacer></v-spacer>
                    <v-btn color="green lighten-1"
                           text
                           @click="FinalizeCharacterEdit">
                        Edit
                    </v-btn>

                    <v-btn color="red lighten-3"
                           text
                           @click="CancelEdit">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>


        <characterEdit v-if="editCharData.characterEditModal" v-bind:characterID="editCharData.cur_CharacterID" @CloseModal="CloseCharModal" @CharacterDataUpdated="CharacterDataUpdated"></characterEdit>

    </div>

</template>

<script>
    import { HubConnectionBuilder } from "@aspnet/signalr";
    import { DiceRoll } from '@dice-roller/rpg-dice-roller';
    import Axios from 'axios';
    import characterEdit from './character-profiles-internal.vue'

     export default {
        name: 'session-internal',
        components: {
            characterEdit,
        },
          data() {
               return {
                   selected: 0,                
                   sessionData: {},
                   init_records: [],
                   player_records: [],
                   chat_messages: [],
                   character_records: [],
                   connection: null,
                   message: '',
                   owncharacterIDs: [],
                   currentcharacterID: -1,
                   currentcharacterLoadout: {},
                   firstScroll: true,
                   IsGM: this.$store.state.player.IsGM,
                   ATKDialog: false,
                   ATKDialog_ATKDFNStatus: true,
                   selectedCharacters: [],
                    selectedIDs: [],




                   atkModalData: {
                         rangedDistance: 0.0,
                         rangedSelfMove: 0.0,
                         rangedTargetMove: 0.0,
                         rangedPenalty: 0.0,
                         targetFactor: 0,

                         atkPenalty: 0,
                         atkBonus: 0,
                         hitRoll: 0,

                         //Actual weapon/armor/shield object data
                         selectedWeapon: 0,
                         selectedArmor: 0,
                         selectedShield: 0,

                         //selection arrays for buttons
                         selectedWeapons: [],
                         selectedArmors: [],
                         selectedShields: [],

                   },

                   currentAttackingCharacterID: 0,
                   currentAttackingDistance: 0,

                   /*edit character data*/
                   editCharData: {
                       selectCharacterDialog: false,
                       list_Characters: [],
                       cur_CharacterID: 0,
                       characterEditModal: false
                   },

                   /*Disabling and loading data*/
                   SelectedCharactersConfirmed: false,
                   communicationLoading: false,
                   turnEnabled: false,
                   DisableCloseATKModal: false,
               }

          },
          methods: {

              /*Established connection with SignalR and declares events*/
              async EstablishSession (session, characterIDs) {

                  this.$store.commit('setLoading', true)
                  this.$store.commit('setinSession', true)

                  let id = session.sessionID;
                  this.sessionData = session;
                  this.owncharacterIDs = characterIDs;
                  this.setCurrentCharacterData(characterIDs[0])
                  

                  console.log("*Establishing connection to session : ", id);

                  //Then we need to establish a connection and be added to a signalR group
                  this.connection = new HubConnectionBuilder()
                      .withUrl("/session")
                      .build();

                  //Now we need to define the event listeners 
                  this.connection.on("ReceiveMessage", (message_entry) => {
                      this.chat_messages.push(message_entry);
                      this.scrollChatBox();
                      
                  });

                  //When a new person joins we will append the new init record
                  this.connection.on("NewInitRecord", (init_entry) => {

                      //Check if the init record already exists
                      let index = this.init_records.findIndex(i => i.initID_ChrcProfileID === init_entry.initID_ChrcProfileID);
                     

                      //If it does not append
                      if (index == -1) {
                          this.init_records.push(init_entry);
                          this.sortInitTable();
                      }
                   
                  });

                  //When a person leaves we will need to remove the init record
                  this.connection.on("RemoveInitRecord", (init_entry) => {
                      let index = this.init_records.findIndex(i => i.initID === init_entry.initID);
                      

                      //If it does exist remove it
                      if (index != -1) {
                          this.init_records.splice(index, 1);
                          this.sortInitTable();




                          if (this.selected.initID == init_entry.initID) {
                              this.selected = 0;
                          }
                      }


                      
                  });

                  //When a person gets signaled with a new init_value
                  this.connection.on("RecieveUpdatedInit", (new_init_entry) => {
                      let index = this.init_records.findIndex(i => i.initID === new_init_entry.initID);
                      this.init_records[index] = new_init_entry;

                      if (this.selected.initID == new_init_entry.initID) {
                          this.selected = 0;
                          this.selected = new_init_entry;
                      }


                     
                      //Need to wait a small amount while the data is rendered
                      setTimeout(() => {
                          this.sortInitTable();
                      }, 50);
                      
                  });

                  //When a new person joins we will append the new character record
                  this.connection.on("NewCharacterRecord", (character_entry) => {

                      //Check if the character record already exists
                      let index = this.character_records.findIndex(i => i.character.characterID === character_entry.characterID);


                      //If it does not append
                      if (index == -1) {
                          this.character_records.push(character_entry);
                      }

                      
                  });

                  //When a person leaves we will need to remove the character record
                  this.connection.on("RemoveCharacterRecord", (init_entry) => {
                      let index = this.character_records.findIndex(i => i.character.characterID === init_entry.initID_ChrcProfileID);

                      if (index != -1) {
                          this.character_records.splice(index, 1);
                      }                    
                      
                  });

                  //When a person makes an edit to a character update all records with the new one
                  this.connection.on("RecieveUpdatedCharacterRecord", (new_charloadout_entry) => {
                      let index = this.character_records.findIndex(i => i.character.characterID === new_charloadout_entry.character.characterID);
                      this.character_records[index] = new_charloadout_entry;

                      //Need to wait a small amount while the data is rendered
                      setTimeout(() => {
                          this.sortInitTable();
                      }, 50);

                  });

                  //When a new person joins we will append the new player record
                  this.connection.on("NewPlayerRecord", (player_entry) => {

                      //Check if the player record already exists
                      let index = this.player_records.findIndex(i => i.playerID === player_entry.playerID);

                      //If it does not append
                      if (index == -1) {
                          this.player_records.push(player_entry);
                      }

                      
                  });

                  //When a person leaves we will need to remove the player record
                  this.connection.on("RemovePlayerRecord", (player_entry) => {
                      let index = this.player_records.findIndex(i => i.playerID === player_entry.playerID);

                      //If it does exist remove it
                      if (index != -1) {
                          this.player_records.splice(index, 1);
                      }


                      
                  });

                  //When a person join they will need to recieve the current session data
                  this.connection.on("RecieveCurrentSessionData", (session_data) => {

                     
                      this.chat_messages = session_data.message_list;
                      this.init_records = session_data.init_list;
                      this.player_records = session_data.players_list;
                      this.character_records = session_data.characters_list;
                      this.sortInitTable();
                      this.scrollChatBox();

                      //Need to wait a small amount while the data is rendered
                      setTimeout(() => {
                          this.FilterDMMessages();
                          this.$store.commit('setLoading', false);
                      }, 50);
                      
                     
                  });

                  //When a person is signaled
                  this.connection.on("RecieveSignal", (characterID) => {

                      this.$swal.fire({
                          title: 'GO!',
                          text: "It's your turn " + this.LookupCharacterName(characterID) + "!",
                          icon: 'success',
                      });


                      this.turnEnabled = true
                      this.setCurrentCharacterData(characterID)

                  });

                  //When a person is signaled to roll for initative
                  await this.connection.on("Rollforinitative", () => {                  
                      this.handle_rollforinitative();
                  });


                  //When a character is attacked (DFND) -- produce values and send back to attacker
                  await this.connection.on("DefendFromCharacter", (currentAttackingCharacterID, rangedDistance, currentDefendingCharacterID) => {
                      this.HandleDefending(currentAttackingCharacterID, rangedDistance, currentDefendingCharacterID);
                  });

                  //When a character is attacked (DFND) -- values produced continue attack loop
                  await this.connection.on("ResumeAttackOnCharacter", (DefenderData) => {
                      this.HandleAttackLoop(DefenderData);
                  });

                  //Start the connection 
                  
                  await this.connection.start();
                  await this.connection.invoke("JoinSession", String(id), this.$store.state.player.PlayerID, 0, this.owncharacterIDs);
              },
              async handle_rollforinitative() {

                  //Iterate through each character this player joined the session with
                  for (var i = 0; i < this.owncharacterIDs.length; i++) {

                      //Retrieve the current char data
                      let char = this.LookupCharacter(this.owncharacterIDs[i])

                      //If found roll
                      if (char) {

                          //Prompt to the player which character
                          await this.$swal.fire({
                              icon: 'info',
                              title: 'Roll for initative!',
                              allowOutsideClick: false,
                              text: char.character.name,
                              confirmButtonText: 'Ok',


                          })

                          let autoroll = char.character.autoroll
                          let diceRoll = "3d6 + " + String(char.character.initBonus)
                          let initval = await this.rollDice(diceRoll, autoroll)

                          await this.connection.invoke("UpdateInitative", this.sessionData.sessionID, char.character.characterID, initval);
                          console.log("Done")
                      }
                      
                  }


              },
              async sendMessage() {

                   //If the ID = 0 then we send to everyone else to specific person
                   let init_record = this.selected;
                   let DMFlag = true;

                   //If the selected ID == 0 then it is a message to everyone
                   if (init_record == 0)
                       DMFlag = false;

                   //For now we do not know the playerID 
                   let messageForm = {
                       chatmessageID: 0,
                       chatmessageID_SessionID: this.sessionData.sessionID,
                       chatmessageID_ToplayerID: init_record.initID_PlayerID,
                       chatmessageID_FromplayerID: this.$store.state.player.PlayerID,
                       message: this.message,
                       isDM: DMFlag,
                       fromCharacterName: this.LookupCharacterName(this.currentcharacterID)
                   }

                  await this.connection.invoke("SendMessage", messageForm);

                  this.clearMessage()
              },
              async sendSystemMessage(messagetxt) {

                 
                  let messageForm = {
                      chatmessageID: 0,
                      chatmessageID_SessionID: this.sessionData.sessionID,
                      chatmessageID_ToplayerID: 0,
                      chatmessageID_FromplayerID: 0,
                      message: messagetxt,
                      isDM: false,
                      fromCharacterName: "System-Event"
                  }

                  await this.connection.invoke("SendMessage", messageForm);

                 
              },
              async LeaveSession() {

                  this.$store.commit('setLoading', true);
                  this.$store.commit('setinSession', false);

                  //First we need to kill the signalR connection 
                  await this.connection.stop();

                  //Reset all our data
                  this.selected = 0;
                  this.sessionData = {};
                  this.init_records = [];
                  this.player_records = [];
                  this.chat_messages = [];
                  this.character_records = [];
                  this.connection = null;
                  this.message = '';
                  this.owncharacterIDs = [];
                  this.currentcharacterLoadout = {};
                  this.selectedCharacters = []
                  this.selectedIDs = []
                  this.currentAttackingCharacterID = 0
                  this.currentAttackingDistance = 0
                  this.atkModalData = {
                      rangedDistance: 0.0,
                      rangedSelfMove: 0.0,
                      rangedTargetMove: 0.0,
                      rangedPenalty: 0.0,
                      targetFactor: 0,

                      atkPenalty: 0,
                      atkBonus: 0,
                      hitRoll: 0,

                      //Actual weapon/armor/shield object data
                      selectedWeapon: 0,
                      selectedArmor: 0,
                      selectedShield: 0,

                      //selection arrays for buttons
                      selectedWeapons: [],
                      selectedArmors: [],
                      selectedShields: [],

                  }

                  this.editCharData = {
                      selectCharacterDialog: false,
                      list_Characters: [],
                      cur_CharacterID: 0,
                      characterEditModal: false
                  }

                  this.currentcharacterID = -1
                  this.SelectedCharactersConfirmed = false
                  this.communicationLoading = false
                  this.turnEnabled = false
                  this.DisableCloseATKModal = false

                  this.firstScroll = true;
                  this.$emit('LeftSession');

                  this.$store.commit('setLoading', false);
              },
              async clearMessage() {
                    this.message = ''
              },
              FilterDMMessages() {

                  for (var i = this.chat_messages.length -1 ; i >= 0; i--) {

                      let myPlayerID = this.$store.state.player.PlayerID;

                      //We only need to filter DMs that are not for us

                      if (this.chat_messages[i].isDM)
                      {

                          if (this.chat_messages[i].chatmessageID_ToplayerID == myPlayerID || this.chat_messages[i].chatmessageID_FromplayerID == myPlayerID)
                          {           
                              /*console.log("Kept DM : ", this.chat_messages[i])*/
                          }

                          else
                          {                            
                             /* console.log("Remove : ", this.chat_messages[i])*/
                              this.chat_messages.splice(i, 1)
                          }

                      }

                      else {
                          //Leave as is
                      }


                  }

                  
              },
              async buttonStart() {
                   console.log('Start!');
                  let sessionID = this.sessionData.sessionID;
                  this.sendSystemMessage("Initiative Table START!")
                  await this.connection.invoke("Rollforinitatives", sessionID);
              
               },
              async buttonEnd() {
                  console.log('End!');
                  let sessionID = this.sessionData.sessionID;
                  this.sendSystemMessage("Initiative Table END!")
                  await this.connection.invoke("EndSession", sessionID);
               },
              async buttonGo() {

                  let sessionID = this.sessionData.sessionID;
                  let characterID = this.init_records[0].initID_ChrcProfileID;
                  await this.connection.invoke("SignalThisPlayer", sessionID, characterID);
                  this.sendSystemMessage("It's " + this.LookupCharacterName(characterID) + " turn!")
               },
              async buttonConfirm() {
                   console.log('Confirm!');
                   this.SelectedCharactersConfirmed = !this.SelectedCharactersConfirmed
               },
              async buttonATK() {
                  console.log('ATK!');


                  if (this.selectedCharacters.length == 0 || this.SelectedCharactersConfirmed == false) {

                      this.$swal.fire({
                          title: 'Error',
                          text: "Please select targets to attack and confirm the selection",
                          icon: 'error',
                      });

                      return;
                  }

                  else {
                      this.OpenATKDFNModal(true)
                      this.DisableCloseATKModal = false
                  }


                
               },
              async buttonRPTATK() {
                   console.log('RPT ATK!');

                   //Used for debugging ATK Modal
                   //Comment out for release/demo
                   this.OpenATKDFNModal(true)
                   this.DisableCloseATKModal = false
               },
              async buttonMove() {

                  console.log('Move!');

                  //Retrieve character data
                  let currentCharacterID = this.currentcharacterID
                  let currentCharacter = this.LookupCharacter(currentCharacterID)


                  const { value: moveVal } = await this.$swal.fire({
                      icon: 'info',
                      title: 'MOVE!',
                      text: 'Manually enter how much you will MOVE !',
                      input: 'number',
                      allowOutsideClick: false,
                      inputPlaceholder: 'EX: 3',
                      confirmButtonText: 'Submit',
                      inputValidator: (value) => {
                          return new Promise((resolve) => {
                              if (value && value > 0) {
                                  resolve()
                              } else {
                                  resolve('You need to enter a valid value')
                              }
                          })
                      }
                  })

                  if (moveVal) {

                      let move = parseInt(moveVal);

                      //Disable the UI of the player
                      this.turnEnabled = false
                      //Write messages
                      await this.Swal("info", 'MOVE!', "You have decided to MOVE " + move + " meter(s)!")
                      this.sendSystemMessage(currentCharacter.character.name + " is moving " + move + " meter(s)!")
                      //End turn
                      this.characterTurnEnded(currentCharacter.character)

                  }




               },
              async buttonDFND() {
                    console.log('DFND!');
               },
              async buttonHold() {
                  console.log('Hold!');

                  //Retrieve character data
                  let currentCharacterID = this.currentcharacterID
                  let currentCharacter = this.LookupCharacter(currentCharacterID)

                  //Disable the UI of the player
                  this.turnEnabled = false
                  //Write messages
                  await this.Swal("info", 'HOLD!', "You have decided to HOLD!")
                  this.sendSystemMessage(currentCharacter.character.name + " is holding!")
                  //End turn
                  this.characterTurnEnded(currentCharacter.character)

               },
              async buttonWait() {
                  //Retrieve character data
                  let currentCharacterID = this.currentcharacterID
                  let currentCharacter = this.LookupCharacter(currentCharacterID)

                  //Disable the UI of the player
                  this.turnEnabled = false
                  //Write messages
                  await this.Swal("info", 'WAIT!', "You have decided to WAIT!")
                  this.sendSystemMessage(currentCharacter.character.name + " is waiting!")

                  //End turn different initative reduction from the rest                 
                  let sessionID = this.sessionData.sessionID;
                  let characterID = currentCharacter.character.characterID
                  await this.connection.invoke("UpdateCharacterInitative_WAIT", sessionID, characterID);
                  this.SelectedCharactersConfirmed = false
                  this.sendSystemMessage(currentCharacter.character.name + "'s turn has ended !")
                  this.scrollChatBox();


               },
              async buttonINTRV() {
                    console.log('INTRV!');
               },
              async buttonEdit() {
                   console.log('Edit!');
                   await this.UpdateCharacterList()
               },

               usThemColor: function (team) {
                    if (team == 'US') {
                         return '#5EB8FF';
                    }
                    else {
                         return '#757DE8';
                    }
               },

              async selectWeapon(wep) {
                   console.log('WPNSelect! ff');              
                   this.atkModalData.selectedWeapon = wep.weaponID;
               },
              async selectArmor(arm) {
                    console.log('ARMSelect!');
                    this.atkModalData.selectedArmor = arm.armorID;
               },
              async selectShield(shd) {
                    console.log('SHDSelect!');
                    this.atkModalData.selectedShield = shd.shieldID;
               },
              async characterSelect(id) {
                    console.log('character ID selection')
                    //if (this.currentcharacterID != id) {
                         if (this.selectedIDs.includes(id)) {
                              let index = this.selectedIDs.indexOf(id);
                              this.selectedIDs.splice(index, 1);
                         }
                         else {
                              this.selectedIDs.push(id);
                         }
                    //}
               },

              //Helper function that looks up the player username based on the passed ID
              LookupPlayerName: function (playerID) {
             
                  let index = this.player_records.findIndex(i => i.playerID === playerID);
                  if (index >= 0)
                      return this.player_records[index].username;
                  else
                      return "No player name"
               },


              //Helper function that looks up the player ID based on the characterID
              LookupPlayerID: function (characterID) {

                  let index = this.init_records.findIndex(i => i.initID_ChrcProfileID === characterID);
                  if (index >= 0)
                      return this.init_records[index].initID_PlayerID;
                  else
                      return 0
              },
              //Helper function that looks up the player username based on the passed ID
              LookupCharacterName: function (characterID) {

                  let index = this.character_records.findIndex(i => i.character.characterID === characterID);
                  if (index >= 0)
                      return this.character_records[index].character.name;
                  else
                      return "System"
               },
              //Helper function that looks up the player team based on the passed ID
               LookupCharacterTeam: function (characterID) {

                    let index = this.character_records.findIndex(i => i.character.characterID === characterID);
                    if (index >= 0)
                         return this.character_records[index].character.team;
                    else
                         return "no team"
               },

              //Helper function that looks up the player username based on the passed ID
              LookupCharacterAutoroll: function (characterID) {

                  let index = this.character_records.findIndex(i => i.character.characterID === characterID);
                  if (index >= 0)
                      return this.character_records[index].character.autoroll;
                  else
                      return true
              },
              LookupCharacter: function (characterID) {

                  let index = this.character_records.findIndex(i => i.character.characterID === characterID);
                  if (index >= 0)
                      return this.character_records[index]
                  else
                      return null
              },
              //These item function return 0'ed items if cannot be found (defense = 0)
              LookupCharacterWeapon: function (characterID, weaponID) {

                  let index = this.character_records.findIndex(i => i.character.characterID === characterID);
                  if (index >= 0) {
                      let char = this.character_records[index];
                     
                      let index2 = char.weapons.findIndex(i => i.weaponID === weaponID);

                      if (index2 >= 0)
                          return char.weapons[index2]
                      else {
                          let weapon = {
                              weaponID: 0,
                              weaponID_CharacterID: 0,
                              name: "",
                              weaponClass: 0,
                              wcbonus: "",
                              IsRange: true,
                              base: 0,
                              shots: 0,
                              damage: "",
                              damageType: true,
                              chatMessage: ""
                          }

                          return weapon
                      }

                      
                  }

                  else {
                      
                      let weapon = {
                          weaponID: 0,
                          weaponID_CharacterID: 0,
                          name: "",
                          weaponClass: 0,
                          wcbonus: "",
                          IsRange: true,
                          base: 0,
                          shots: 0,
                          damage: "",
                          damageType: true,
                          chatMessage: ""
                      }

                      return weapon
                  }
                      
              },
              LookupCharacterArmor: function (characterID, armorID) {

                  let index = this.character_records.findIndex(i => i.character.characterID === characterID);
                  if (index >= 0) {
                      let char = this.character_records[index];
                      let index2 = char.armors.findIndex(i => i.armorID === armorID);

                      if (index2 >= 0)
                          return char.armors[index2]
                      else {
                          let armor = {
                              armorID: 0,
                              armorID_CharacterID: 0,
                              name: "",
                              location: "",
                              armorClass: 0,
                              asb: 0,
                              rflt: 0
                          }

                          return armor
                      }

                      
                  }

                  else {
                      let armor = {
                          armorID: 0,
                          armorID_CharacterID: 0,
                          name: "",
                          location: "",
                          armorClass: 0,
                          asb: 0,
                          rflt: 0
                      }

                      return armor
                  }
              },
              LookupCharacterShield: function (characterID, shieldID) {

                  let index = this.character_records.findIndex(i => i.character.characterID === characterID);
                  if (index >= 0) {
                      let char = this.character_records[index];
                      let index2 = char.shields.findIndex(i => i.shieldID === shieldID);

                      if (index2 >= 0)
                          return char.shields[index2]
                      else {
                          let shield = {
                              shieldID: 0,
                              shieldID_CharacterID: 0,
                              name: "",
                              location: "",
                              armorClass: 0,
                              asb: 0,
                              rflt: 0,
                              percent: 0
                          }

                          return shield
                      }

                      
                  }

                  else {

                      let shield = {
                          shieldID: 0,
                          shieldID_CharacterID: 0,
                          name: "",
                          location: "",
                          armorClass: 0,
                          asb: 0,
                          rflt: 0,
                          percent: 0
                      }

                      return shield
                  }
                      
              },
              sortInitTable ()
              {
                  let copy_records = this.init_records.sort((a, b) => (b.initval - a.initval));
                  this.init_records = copy_records;
              },
              scrollChatBox() {
                  //setTimeout(() => {
                  //    this.$el
                  //        .getElementsByClassName("chatmessage")
                  //    [
                  //        this.$el.getElementsByClassName("chatmessage").length -
                  //        1
                  //    ].scrollIntoView();
                  //}, 50);

                  var scrollBox = document.getElementsByClassName("chatbox")[0];

                  //if (this.firstScroll) {

                  //    setTimeout(() => {                   
                  //        scrollBox.scrollTop = scrollBox.scrollHeight;
                  //    }, 50);

                  //    this.firstScroll = false;
                  //}

                  //else if (scrollBox.scrollTop + scrollBox.clientHeight >= scrollBox.scrollHeight) {
                  //    setTimeout(() => {
                  //        scrollBox.scrollTop = scrollBox.scrollHeight;
                  //    }, 50);
                  //}


                  setTimeout(() => {
                      scrollBox.scrollTop = scrollBox.scrollHeight;
                  }, 50);
                  
                    
              },
              //This function will remove the person's own init record for the message dropdown list
              FilterInit(init_records) {

                  let filterd_init_records = [...init_records] ;


                  for (var i = 0; i < this.owncharacterIDs.length; i++) {

                      let currCharID = this.owncharacterIDs[i];
                      let index = filterd_init_records.findIndex(i => i.initID_ChrcProfileID === currCharID);
                      if (index >= 0)
                          filterd_init_records.splice(index, 1)
                  }
                 
                  return filterd_init_records;

              },
              OwnCharacter(characterID) {

                 return this.owncharacterIDs.includes(characterID)

              },
              //This function will handle our dice calculations returns a int
              async rollDice(dice, autoroll) {

                  try {

                      //If autoroll is true, we will just return the value of the rolled dice
                      if (autoroll) {


                          const roll = new DiceRoll(dice);


                          await this.$swal.fire({
                              icon: 'success',
                              title: dice + ' Auto roll',
                              text: 'You rolled a ' + roll.output,

                          })
                          return roll.total;

                      }
                      //If autoroll is false, we will use a swal and wait for input of the value
                      else {

                          const { value: roll } = await this.$swal.fire({
                              icon: 'info',
                              title: 'Manually enter your dice roll ! \n' + dice,
                              input: 'number',
                              allowOutsideClick: false,
                              inputPlaceholder: 'EX: 23',
                              confirmButtonText: 'Submit',
                              inputValidator: (value) => {
                                  return new Promise((resolve) => {
                                      if (value && value > 0) {
                                          resolve()
                                      } else {
                                          resolve('You need to enter a valid value')
                                      }
                                  })
                              }
                          })

                          if (roll) {

                              await this.$swal.fire({
                                  icon: 'success',
                                  title: dice + ' roll',
                                  text: 'You rolled a ' + roll,

                              })

                              return parseInt(roll);
                          }


                      }
                  } catch (error) {

                      await this.$swal.fire({
                          icon: 'error',
                          title: dice + ' roll',
                          text: 'This dice was an invalid input. Value of 0 will be used.',

                      })

                      return 0;
                  }




              },
              updateCurrentCharacterLoadout() {


                  //Need to define this instance for the axios request
                  var vm = this;

                  //Retrieve the record we want to edit given the ID from the button selection
                  Axios.get('api/Loadout/' + this.currentcharacterID).then(response => {

                      //If our response was succesful
                      if (response.status === 200) {
                         this.currentcharacterLoadout = response.data                     
                      }
                      //If an error occured
                      else {                   
                          this.$swal.fire({
                              icon: 'error',
                              title: 'Character',
                              text: "An error occurred while retrieving the character data."
                          });
                      }
                  }).catch(response => {

                      this.$swal.fire({
                          icon: 'error',
                          title: 'Character',
                          text: "An error occurred while retrieving the character data."
                      });
                  });


              },
              setCurrentCharacterData(characterID) {

                  this.currentcharacterID = characterID;
                  this.updateCurrentCharacterLoadout()
              },

              /*character edit functions*/
              FinalizeCharacterEdit() {

                  if (this.editCharData.cur_CharacterID == 0) {
                      this.$swal.fire({
                          title: 'Error',
                          text: "Please select a valid character",
                          icon: 'error',
                      });
                  }

                  else {
                      this.characterEditProcedure();
                  }


             
                  
              },
              async UpdateCharacterList() {

                  this.$store.commit('setLoading', true);

                  let vm = this;
                  await Axios.get('api/GetSessionCharacters/' + vm.sessionData.sessionID)
                      .then(response => {
                          vm.editCharData.list_Characters = response.data;
                          vm.editCharData.selectCharacterDialog = true

                      })
                      .catch(error => {
                          console.log(error);
                      });

                  this.$store.commit('setLoading', false);

              },
              CancelEdit() {
                  this.editCharData.selectCharacterDialog = false
              },
              characterEditProcedure() {
                  
                  this.editCharData.selectCharacterDialog = false
                  this.editCharData.characterEditModal = true

                  /*setup the rest*/
                 
              },
              CloseCharModal() {
                  this.editCharData.characterEditModal = false
              },
              async CharacterDataUpdated(characterID) {
                  await this.connection.invoke("UpdateCharacter", this.sessionData.sessionID, characterID);
              },
              async UpdateCharacterData(character) {



                  await Axios.put('api/Character/' + character.characterID, character).then(response => {

                      if (response.status == 200) {

                          //Send signalR event 
                          this.CharacterDataUpdated(response.data.characterID);
                          console.log("Character updated event sent")                         
                      }

                      else {

                          //Display an error message to the user
                          this.$swal.fire({
                              icon: 'error',
                              title: 'Updating',
                              text: "The character's data was not updated!" 
                          });
                      }

                  }).catch(response => {
                      //If an error occured
                      //Display an error message to the user
                      this.$swal.fire({
                          icon: 'error',
                          title: 'Updating',
                          text: "The character's data was not updated!"
                      });
                  });






              },

              /*Modal functions*/
              ResetATKModalData() {

                  this.atkModalData.atkModalData = 0.0
                  this.atkModalData.rangedSelfMove = 0.0
                  this.atkModalData.rangedTargetMove = 0.0
                  this.atkModalData.rangedPenalty = 0.0
                  this.atkModalData.targetFactor = 0.0

                  this.atkModalData.atkPenalty = 0
                  this.atkModalData.atkBonus = 0
                  this.atkModalData.hitRoll = 0

                  this.atkModalData.selectedWeapon = 0
                  this.atkModalData.selectedArmor = 0
                  this.atkModalData.selectedShield = 0

                  this.atkModalData.selectedWeapons = []
                  this.atkModalData.selectedArmors = []
                  this.atkModalData.selectedShields = []
              },
              OpenATKDFNModal(atkordfn) {
                  this.ATKDialog = true;

                  //This determines if we are attacking or defending while this modal is open
                  //RE: To execute a different operation when it is closed
                  //Attacking : Nothing just close
                  //Defending : Send data to attacker

                  this.ATKDialog_ATKDFNStatus = atkordfn;
              },
              CloseATKDFNModal() {

                  this.ATKDialog = false;              
              },
              ATKDFNModalConfirm() {

                  //If we are attacking, just close the modal
                  if (this.ATKDialog_ATKDFNStatus) {

                      this.InitiateAttack();
                  }
                  else {

                      this.InitiateDefend();
                  }


              },
              async Swal(icon, title, text) {


                  await this.$swal.fire({
                      icon: icon,
                      title: title,
                      allowOutsideClick: false,
                      text: text,
                      confirmButtonText: 'Ok',


                  })

              },


              async LocationCombatEntry(locationroll) {


                  // Ask for confirmation if they would like to manually enter a location or roll
                  let confirmation = await this.$swal.fire({
                      title: 'Hit Location!',
                      text: "Would you like to manually enter a location or roll? ",
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, roll!',
                      cancelButtonText: 'No, manual entry!'
                  });

                  //If they do, roll for location
                  if (confirmation.isConfirmed == true) {


                      let autoroll = this.LookupCharacterAutoroll(this.currentcharacterID)
                      let diceRoll = locationroll
                      let LocationRoll = await this.rollDice(diceRoll, autoroll)
                      return LocationRoll

                  }

                  else {

                     

                      const { value: roll } = await this.$swal.fire({
                          icon: 'info',
                          title: 'Manually enter the hit location ! \n' + locationroll,
                          input: 'number',
                          allowOutsideClick: false,
                          inputPlaceholder: 'EX: 23',
                          confirmButtonText: 'Submit',
                          inputValidator: (value) => {
                              return new Promise((resolve) => {
                                  if (value && value > 0) {
                                      resolve()
                                  } else {
                                      resolve('You need to enter a valid value')
                                  }
                              })
                          }
                      })

                      if (roll) {

                          

                          return parseInt(roll);
                      }

                  }



              },
              async EndAttackLoopHelper(Attack_Character) {

                  //Pop the current target
                  this.selectedIDs.shift();
                  this.selectedCharacters.shift();


                  //If there is more, repeat procedure
                  if (this.selectedIDs.length == 0) {
                      await this.characterTurnEnded(Attack_Character.character)
                  } else {
                      this.InitiateAttack()
                  }
              },

              /*Combat loop functions*/
              async InitiateAttack() {

                  

                  //We do not want to execute an attack if we have no targets selected
                  if (this.selectedCharacters.length == 0 || this.SelectedCharactersConfirmed == false) {

                      this.$swal.fire({
                          title: 'Error',
                          text: "Please select targets to attack and confirm the selection",
                          icon: 'error',
                      });

                      return;
                  }


                  else {

                      //Close modal
                      this.CloseATKDFNModal()
                      this.turnEnabled = false


                      //Signal to the other player to defend themselves and await values to continue attack loop
                      let currentAttackingCharacterID = this.currentcharacterID
                      let currentDefendingCharacterID = this.selectedIDs[0]


                      //Prompt to the roll for attak
                      await this.$swal.fire({
                          icon: 'info',
                          title: 'ATTACK!',
                          allowOutsideClick: false,
                          text: this.LookupCharacterName(this.currentcharacterID) + " roll to attack " + this.LookupCharacterName(currentDefendingCharacterID) + "!",
                          confirmButtonText: 'Ok',


                      })
                      let autoroll = this.LookupCharacterAutoroll(this.currentcharacterID)
                      let diceRoll = "3d6"
                      let atkrollvalue = await this.rollDice(diceRoll, autoroll)
                      this.atkModalData.hitRoll = atkrollvalue

                                      
                      
                      //Let's retrieve the current character to lookup actions remaining
                      let currentDefendingCharacter = this.LookupCharacter(currentDefendingCharacterID)

                    
                      this.communicationLoading = true
                      await this.connection.invoke("AttackCharacter", this.sessionData.sessionID, currentAttackingCharacterID, currentDefendingCharacterID, this.atkModalData.rangedDistance);

                  }

              },
              async HandleDefending(currentAttackingCharacterID, rangedDistance, currentDefendingCharacterID) {


                  if (this.owncharacterIDs.length > 1) {
                      this.setCurrentCharacterData(currentDefendingCharacterID)
                  }

                  let Defender_Character_Name = this.LookupCharacterName(this.currentcharacterID)

                  await this.$swal.fire({
                      title: 'DEFEND!',
                      text: Defender_Character_Name + " you are being attacked by " + this.LookupCharacterName(currentAttackingCharacterID) + "!",
                      icon: 'info',
                  });




                  let currentDefendingCharacter = this.LookupCharacter(this.currentcharacterID)
                  this.currentAttackingCharacterID = currentAttackingCharacterID
                  this.currentAttackingDistance = rangedDistance
                 

                  this.sendSystemMessage(Defender_Character_Name + " is being attacked by " + this.LookupCharacterName(currentAttackingCharacterID) + "!")

                  //Check if the attack distance is over 2m if it, defender can defende but not roll
                  if (this.currentAttackingDistance > 2) {

                      //We need to get the atkmodal data from the defender and send it back to the attacker 
                      //Reset atkmodal data, perhaps autofilled from last time later on? 
                      this.ResetATKModalData();
                      //Open ATKModal in defend mode
                      this.OpenATKDFNModal(false);
                      //Disable closing the modal when defending because we need the data
                      this.DisableCloseATKModal = true


                  }

                  else {


                      //If the current defending character has actions remaining let's prompt them for the values and continue  
                      if (currentDefendingCharacter.character.numActions > 0) {

                          //We need to get the atkmodal data from the defender and send it back to the attacker 
                          //Reset atkmodal data, perhaps autofilled from last time later on? 
                          this.ResetATKModalData();
                          //Open ATKModal in defend mode
                          this.OpenATKDFNModal(false);
                          //Disable closing the modal when defending because we need the data
                          this.DisableCloseATKModal = true


                      }

                      else {

                          //Inform the player they need to perform a crit save roll to obtain a new action
                          await this.$swal.fire({
                              icon: 'info',
                              title: 'Crit Save Roll!',
                              text: Defender_Character_Name + " you have no actions remaining! \nPerform a crit save roll successfully (>= 21) to obtain one from the next round! ",
                              allowOutsideClick: false,
                              confirmButtonText: 'Ok',
                          })


                          // Ask for confirmation if they would like to perform a crit save roll
                          let confirmation = await this.$swal.fire({
                              title: 'Crit Save Roll!',
                              text: "Would you like to perform one? ",
                              icon: 'question',
                              showCancelButton: true,
                              confirmButtonColor: '#d33',
                              cancelButtonColor: '#3085d6',
                              confirmButtonText: 'Yes, roll!',
                              cancelButtonText: 'No'
                          });

                          //If they do, do it!
                          if (confirmation.isConfirmed == true) {

                              //For the crit save roll we use either the AGL or MD state, we will use higher stat
                              let MDstat = currentDefendingCharacter.character.md
                              let AGLstat = currentDefendingCharacter.character.agl
                              let critsavestat = 0

                              if (MDstat > AGLstat) {
                                  critsavestat = MDstat
                              } else {
                                  critsavestat = AGLstat
                              }


                              let autoroll = this.LookupCharacterAutoroll(this.currentcharacterID)
                              let diceRoll = "3d6 + (0.5*" + critsavestat.toString() + ")"
                              let critSaveRoll = await this.rollDice(diceRoll, autoroll)

                              //If they obtained a succesful crit roll
                              if (critSaveRoll >= 21) {

                                  await this.$swal.fire({
                                      icon: 'success',
                                      title: 'Crit Save Roll!',
                                      text: "You performed a successful crit save roll! ",
                                      allowOutsideClick: false,
                                      confirmButtonText: 'Awesome!',
                                  })

                                  //WRITE TO CHAT EVENT
                                  this.sendSystemMessage(Defender_Character_Name + " performed a successful crit save roll!")

                                  //We need to get the atkmodal data from the defender and send it back to the attacker 
                                  //Reset atkmodal data, perhaps autofilled from last time later on? 
                                  this.ResetATKModalData();
                                  //Open ATKModal in defend mode
                                  this.OpenATKDFNModal(false);
                                  //Disable closing the modal when defending because we need the data
                                  this.DisableCloseATKModal = true
                                  this.currentAttackingCharacterID = currentAttackingCharacterID

                              }

                              else {

                                  await this.$swal.fire({
                                      icon: 'error',
                                      title: 'Crit Save Roll!',
                                      text: "You did not successfully perform a crit save roll! ",
                                      allowOutsideClick: false,
                                      confirmButtonText: 'Darn',
                                  })


                                  let rollvalue = 0

                                  let DefenderData = {
                                      characterID: this.currentcharacterID,
                                      rangedDistance: 0,
                                      rangedSelfMove: 0,
                                      rangedTargetMove: 0,
                                      rangedPenalty: 0,
                                      targetFactor: 0,
                                      atkPenalty: 0,
                                      atkBonus: 0,
                                      hitRoll: rollvalue,
                                      selectedArmor: 0,
                                      selectedShield: 0
                                  }

                                  this.sendSystemMessage(Defender_Character_Name + " did not perform a successful crit save roll")

                                  //Send data back to attacker                            
                                  await this.connection.invoke("SendDefendDataToCharacter", this.sessionData.sessionID, this.currentAttackingCharacterID, DefenderData);

                              }
                          }

                          else {


                              await this.$swal.fire({
                                  icon: 'error',
                                  title: 'Crit Save Roll!',
                                  text: "You did not perform a crit save roll! ",
                                  allowOutsideClick: false,
                                  confirmButtonText: 'Ok',
                              })


                              let rollvalue = 0

                              let DefenderData = {
                                  characterID: this.currentcharacterID,
                                  rangedDistance: 0,
                                  rangedSelfMove: 0,
                                  rangedTargetMove: 0,
                                  rangedPenalty: 0,
                                  targetFactor: 0,
                                  atkPenalty: 0,
                                  atkBonus: 0,
                                  hitRoll: rollvalue,
                                  selectedArmor: 0,
                                  selectedShield: 0
                              }

                              this.sendSystemMessage(Defender_Character_Name + " did not perform a crit save roll")

                              //Send data back to attacker                            
                              await this.connection.invoke("SendDefendDataToCharacter", this.sessionData.sessionID, this.currentAttackingCharacterID, DefenderData);





                          }




                      }



                  }



                 


                  

                  
              },
              async InitiateDefend() {

                  this.CloseATKDFNModal();

                  let atkrollvalue = 0


                  //Check if the attack distance is over 2m if it, defender can defende but not roll
                  if (this.currentAttackingDistance > 2) {                     
                      atkrollvalue = 0
                  }

                  else {


                      //Prompt to the roll for attak
                      await this.$swal.fire({
                          icon: 'info',
                          title: 'Roll for defense!',
                          allowOutsideClick: false,
                          text: this.LookupCharacterName(this.currentcharacterID),
                          confirmButtonText: 'Ok',


                      })

                      let autoroll = this.LookupCharacterAutoroll(this.currentcharacterID)
                      let diceRoll = "3d6"
                      atkrollvalue = await this.rollDice(diceRoll, autoroll)



                  }
            

                  let DefenderData = {
                      characterID: this.currentcharacterID,
                      rangedDistance: this.atkModalData.rangedDistance,
                      rangedSelfMove: this.atkModalData.rangedSelfMove,
                      rangedTargetMove: this.atkModalData.rangedTargetMove,
                      rangedPenalty: this.atkModalData.rangedPenalty,
                      targetFactor: this.atkModalData.targetFactor,
                      atkPenalty: this.atkModalData.atkPenalty,
                      atkBonus: this.atkModalData.atkBonus,
                      hitRoll: atkrollvalue,
                      selectedArmor: this.atkModalData.selectedArmor,
                      selectedShield: this.atkModalData.selectedShield
                  }

                  //Roll probably needs to be done here
                 
                  //Send data back to attacker
                  //Need to define the defender data object in c# so that signalR accepts it, send it back to 
                  await this.connection.invoke("SendDefendDataToCharacter", this.sessionData.sessionID, this.currentAttackingCharacterID, DefenderData);
                  
                  

              },
              async HandleAttackLoop(DefenderData) {


                  /*POSSIBLE INTERVENTION HERE*/
                  /*We have the defender, signal to others for possible intervention? Then if someone does re-do defense procedure?*/

                  console.log("Defender data RECV : ", DefenderData)
                  this.communicationLoading = false

                  //Let's obtain the armor and shield the defender is going to be using 
                  let Defender_Character = this.LookupCharacter(DefenderData.characterID)
                  let Defender_ArmorSelected = this.LookupCharacterArmor(DefenderData.characterID, DefenderData.selectedArmor)
                  let Defender_ShieldSelected = this.LookupCharacterShield(DefenderData.characterID, DefenderData.selectedShield)

                  //Let's obtain the weapon the attacker is going to be using 
                  let Attacker_WeaponSelected = this.LookupCharacterWeapon(this.currentcharacterID, this.atkModalData.selectedWeapon)
                  let Attack_Character = this.LookupCharacter(this.currentcharacterID)
                  let Attack_Character_Name = this.LookupCharacterName(this.currentcharacterID)

                  //For each weapon shot
                  for (var i = 0; i < Attacker_WeaponSelected.shots; i++) {

                      //Check if attack is ranged and in close range
                      if (Attacker_WeaponSelected.isRange && this.atkModalData.rangedDistance <= 2) {

                          

                          await this.Swal("info", 'Bring to Bear Roll!', Attack_Character_Name + " your close attack range to the defender requires you to make a Bring to Bear roll! ")

                          //Prompt for bring to bear roll
                          //For the bring to bear rolll we use either the SPK or MD state, we will use higher stat
                          let MDstat = Attack_Character.character.md
                          let SPKstat = Attack_Character.character.spk
                          let bringtobearstat = 0

                          if (MDstat > SPKstat) {
                              bringtobearstat = MDstat
                          } else {
                              bringtobearstat = SPKstat
                          }


                          let autoroll = Attack_Character.character.autoroll
                          let diceRoll = "3d6 + " + bringtobearstat.toString()
                          let bringtobearRoll = await this.rollDice(diceRoll, autoroll)

                          //If they obtained a succesful bring to bear roll
                          if (bringtobearRoll >= 21) {

                              await this.Swal("success", 'Bring to Bear Roll!', "You performed a successful Bring to Bear roll! ")
                              //WRITE TO CHAT EVENT
                              this.sendSystemMessage(Attack_Character_Name + " performed a successful Bring to Bear roll! +6 Attack Bonus!")

                              this.atkModalData.atkBonus = this.atkModalData.atkBonus + 6

                          }

                          else {

                              await this.Swal("error", 'Bring to Bear Roll!', "You did not successfully perform a Bring to Bear roll! ")
                              //WRITE TO CHAT EVENT
                              this.sendSystemMessage(Attack_Character_Name + " did not perform a successful Bring to Bear roll!")
                              this.sendSystemMessage(Attack_Character_Name + "'s " + Attacker_WeaponSelected.name + " shot missed !")

                              //Exit this for loop but continue to the next iteration 
                              continue;

                          }


                      }

                      //Check if the attack hits
                      let AttackerHitsTotal = Attacker_WeaponSelected.base + this.atkModalData.hitRoll + this.atkModalData.atkBonus - this.atkModalData.atkPenalty - this.atkModalData.rangedPenalty
                      let DefenderHitsTotal = DefenderData.hitRoll + DefenderData.atkBonus - DefenderData.atkPenalty
                     
                      if (AttackerHitsTotal > DefenderHitsTotal) {

                          //Attack can hit
                          await this.Swal("info", "Attack Can Hit!", "Your shot can hit " + Defender_Character.character.name + "!")
                          this.sendSystemMessage(Attack_Character_Name + "'s " + "(" + AttackerHitsTotal + ") " + "shot can hit " + Defender_Character.character.name + " (" + DefenderHitsTotal + ")"  + "!")
                          

                          //Prompt to roll for location 
                          let locationVal = 0
                          if (this.atkModalData.rangedDistance > 10)
                              locationVal = await this.LocationCombatEntry("d40")                        
                          else
                              locationVal = await this.LocationCombatEntry("d80")


                          
                          // Roll shield percentage against defender (random chance out of 100 compared to defender's selected shield d100)
                          let shieldDamage = false
                          if (Attacker_WeaponSelected.isRange) {

                              let randomchance = new DiceRoll("d100");
                              randomchance = randomchance.total

                              //Chance to hit shield, going to hit
                              if (randomchance < Defender_ShieldSelected.percent) {


                                  await this.Swal("info", "Shield Penetration!", "Your ranged attack is going to hit " + Defender_Character.character.name + "'s shield! Roll for penetration!")
                                  this.sendSystemMessage(this.LookupCharacterName(this.currentcharacterID) + "'s ranged attack is going to hit " + Defender_Character.character.name + "'s shield!")

                                  let autoroll = this.LookupCharacterAutoroll(this.currentcharacterID)
                                  let diceRoll = "3d6"
                                  let shieldPenvalue = await this.rollDice(diceRoll, autoroll)

                                  //If the shield is penetrated set flag for extra damage
                                  if (shieldPenvalue >= (Defender_ShieldSelected.armorClass - Attacker_WeaponSelected.weaponClass) * 2 + 11) {

                                      await this.Swal("success", "Shield Penetration!", "Your ranged attack penetrated " + Defender_Character.character.name + "'s shield! Some damage will be reduced")
                                      shieldDamage = true
                                      this.sendSystemMessage(this.LookupCharacterName(this.currentcharacterID) + "'s ranged attack penetrated " + Defender_Character.character.name + "'s shield!")
                                  }

                                  //If the shield did not penetrate attack missed
                                  else
                                  {

                                      await this.Swal("error", "Shield Penetration!", "Your ranged attack did not penetrate " + Defender_Character.character.name + "'s shield! It missed!")
                                      this.sendSystemMessage(this.LookupCharacterName(this.currentcharacterID) + "'s ranged attack missed " + Defender_Character.character.name + "'s shield!")
                                      continue;
                                  }


                              }

                              //Shield hit missed
                              else
                              {

                                  await this.Swal("error", "Shield Attack!", "Your ranged attack missed " + Defender_Character.character.name + "'s shield!")
                                  this.sendSystemMessage(this.LookupCharacterName(this.currentcharacterID) + "'s ranged attack missed " + Defender_Character.character.name + "'s shield!")
                                 
                              }

                          }
                          
                          //Role for armor penetration
                          let armorDamage = false
                          await this.Swal("info", "Armor Penetration!", "Your attack is going to hit " + Defender_Character.character.name + "'s armor! Roll for penetration!")
                          this.sendSystemMessage(Attack_Character_Name + "'s attack is going to hit " + Defender_Character.character.name + "'s armor!")

                          let autoroll = this.LookupCharacterAutoroll(this.currentcharacterID)
                          let diceRoll = "3d6"
                          let armorPenvalue = await this.rollDice(diceRoll, autoroll)
                          

                          if (armorPenvalue >= (Defender_ArmorSelected.armorClass - Attacker_WeaponSelected.weaponClass) * 2 + 11) {

                              await this.Swal("success", "Armor Penetration!", "Your attack penetrated " + Defender_Character.character.name + "'s armor! Some damage will be reduced")
                              armorDamage = true
                              this.sendSystemMessage(Attack_Character_Name + "'s attack penetrated " + Defender_Character.character.name + "'s armor!")
                          }

                          //armor Penetration missed
                          else {

                              await this.Swal("error", "Armor Penetration!", "Your attack missed " + Defender_Character.character.name + "'s armor!")
                              this.sendSystemMessage(Attack_Character_Name + "'s attack missed " + Defender_Character.character.name + "'s armor!")
                              continue;
                          }


                          //Calculate damage
                          await this.Swal("info", "Damage!", "Roll for damage of your weapon!")
                          diceRoll = Attacker_WeaponSelected.damage
                          let damageval = await this.rollDice(diceRoll, autoroll)


                          //Reduce damage if armor or shield were penetrated 
                          let armorDamageReduction = 0
                          let shieldDamageReduction = 0

                          if (armorDamage) {
                              armorDamageReduction = Defender_ArmorSelected.rflt + Defender_ArmorSelected.asb
                              damageval = damageval - armorDamageReduction
                          }
                              
                          if (shieldDamage) {
                              shieldDamageReduction = Defender_ShieldSelected.asb
                              damageval = damageval - shieldDamageReduction
                          }
                              

                          if (damageval < 0) {
                              damageval = 0
                          }

                          //Update character data
                          let oldDefenderHTP = Defender_Character.character.cur_HTP
                          Defender_Character.character.cur_HTP = Defender_Character.character.cur_HTP - damageval
                          await this.UpdateCharacterData(Defender_Character.character)

                          //Write to chat
                          await this.Swal("success", "Damage!", "Your attack dealt " + damageval + " to " + Defender_Character.character.name)
                          this.sendSystemMessage(Attack_Character_Name + "'s attack dealt " + damageval + " damage to " + Defender_Character.character.name + "!" + "\n-Damage reduced by armor : " + armorDamageReduction + "\n-Damage reduced by shield : " + shieldDamageReduction)
                          this.sendSystemMessage("-" + Defender_Character.character.name + "'s location " + locationVal + " received " + damageval + " damage!" + "\n-" + Defender_Character.character.name + "'s Current HTP went from " + oldDefenderHTP + " -> " + Defender_Character.character.cur_HTP + " !")
                          this.sendSystemMessage(Attacker_WeaponSelected.chatMessage)

                         
                          

                          

                          

                          
                      }

                      else {

                          await this.Swal("error", "Attack Missed!", "Your shot against " + Defender_Character.character.name + " missed !")
                          this.sendSystemMessage(Attack_Character_Name + "'s " + "(" + AttackerHitsTotal + ") " + "shot missed against " + Defender_Character.character.name + " (" + DefenderHitsTotal + ")" + "!")
                          continue;

                      }


                  } 



           
                  this.EndAttackLoopHelper(Attack_Character);



              },
              async characterTurnEnded(character) {

                  let sessionID = this.sessionData.sessionID;
                  let characterID = character.characterID
                  await this.connection.invoke("UpdateCharacterInitative", sessionID, characterID);
                  this.SelectedCharactersConfirmed = false
                  this.sendSystemMessage(character.name + "'s turn has ended !")
                  this.scrollChatBox();
              }

                         

          },
          computed: {



               //Range penalty 
               GetRangedPenalty() {
                    let range_pen_fct = 0.0;
                    switch (parseInt(this.atkModalData.targetFactor)) {
                         case 1:
                              range_pen_fct = ((this.atkModalData.rangedDistance * 100) - 500) / (10 * 100);
                              break;
                         case 2:
                              range_pen_fct = ((this.atkModalData.rangedDistance * 100) - 300) / (5 * 100);
                              break;
                         case 3:
                              range_pen_fct = ((this.atkModalData.rangedDistance * 100) - 100) / (3 * 100);
                              break;
                         case 4:
                              range_pen_fct = ((this.atkModalData.rangedDistance * 100) - 200) / (2.5 * 100);
                              break;
                         case 5:
                              range_pen_fct = ((this.atkModalData.rangedDistance * 100) - 200) / (2 * 100);
                              break;
                         default:
                              break;
                    }
                    if (range_pen_fct < 1) {
                         range_pen_fct = 0;
                    }
                    let rngPen = (0 - range_pen_fct - this.atkModalData.rangedSelfMove - this.atkModalData.rangedTargetMove);
                    rngPen = Math.round(rngPen * 100) / 100;
                    this.atkModalData.rangedPenalty = rngPen;
                    return rngPen;


               },
               //Dynamic heights for mobile
               buttonHeight() {
                    switch (this.$vuetify.breakpoint.name) {
                         case 'xs': return 25
                         case 'sm': return 25
                         case 'md': return 35
                         case 'lg': return 35
                         case 'xl': return 35
                    }
               },
               initHeight() {
                    switch (this.$vuetify.breakpoint.name) {
                         case 'xs': return 150
                         case 'sm': return 150
                         case 'md': return 422
                         case 'lg': return 422
                         case 'xl': return 422
                    }
               },
               initPlayerHeight() {
                    switch (this.$vuetify.breakpoint.name) {
                         case 'xs': return 30
                         case 'sm': return 30
                         case 'md': return 40
                         case 'lg': return 40
                         case 'xl': return 40
                    }
               },
               dialogWidth() {
                    switch (this.$vuetify.breakpoint.name) {
                         case 'xs': return 900
                         case 'sm': return 900
                         case 'md': return 900
                         case 'lg': return 1185
                         case 'xl': return 1785
                    }
               },
               chatHeight() {
                    switch (this.$vuetify.breakpoint.name) {
                         case 'xs': return 130
                         case 'sm': return 130
                         case 'md': return 320
                         case 'lg': return 320
                         case 'xl': return 320
                    }
              },

              Filtered_EditCharacterData() {

                  if (this.IsGM) {
                      return this.editCharData.list_Characters
                  }

                  else {

                      let currentCharacterID = this.currentcharacterID

                      return this.editCharData.list_Characters.filter((character) => {
                          return character.characterID == currentCharacterID
                      });
                  }

              },
          },
          mounted() {

           

          }

        
     }
</script>



<style lang="scss">
     @import '../scss/styles.scss';


     .title {
          /*background-color: $test-color;*/
     }


    .dark-background {
        background-color: #283A44;
    }

</style>
