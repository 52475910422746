<template>
    
    <div id="login">

        <!--<v-btn @click="toggleView">Toggle View </v-btn>
        <v-btn @click="SendTestEmail">Send Test Email</v-btn>-->


        <!--Registration view-->
        <v-card color="primary" v-if="regIsActive" max-height="97vh" height="97vh" width="100vw" class="overflow-y-auto">
            <v-container fluid>

                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md5>
                        <v-layout align-center justify-center>
                            <v-img max-width="200" max-height="200" src="../assets/beta logo-01.svg" style="margin-bottom:5px;"></v-img>
                        </v-layout>
                        
                        <br />
                        <v-card class="elevation-12" color="#C5BBB3">

                            <v-card-title>Player Registration</v-card-title>
                            <v-card-subtitle>Please fill out the following form</v-card-subtitle>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>

            <v-container fluid>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md5>
                        
                        <v-form v-model="isFormRegValid" ref="regform">
                            <v-card class="elevation-12" color="#C5BBB3">
                                <v-card-text>
                                    <v-text-field label="Username*"
                                                  :rules="[rules.required]"
                                                  v-model="playerReg_Form.Username"
                                                  data-cy="uname-input"
                                                  ></v-text-field>
                                    <v-text-field label="Email*"
                                                  :rules="[rules.required, rules.emailRules]"
                                                  v-model="playerReg_Form.Email"
                                                  data-cy="email-input"
                                                  ></v-text-field>
                                    <v-text-field label="Password*" data-cy="password-input"
                                                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                  :rules="[rules.required, rules.min]"
                                                  :type="show1 ? 'text' : 'password'"
                                                  v-model="playerReg_Form.Password"
                                                  @click:append="show1= !show1">
                                    </v-text-field>

                                    <v-text-field label="Retype password*" data-cy="password2-input"
                                                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                  :rules="[rules.required, rules.min, rules.matchesPassword]"
                                                  :type="show1 ? 'text' : 'password'"
                                                  v-model="playerReg_Form.CheckPassword"
                                                  @click:append="show1= !show1">
                                    </v-text-field>
                                    <small>*indicates required fields</small>
                                    <br />
                                    <v-btn :disabled="!isFormRegValid" color="#FF4500" light @click="CheckCode" class="mr-2">Submit</v-btn>
                                    <v-btn color="#FF4500" light @click="toggleView">Return to Login</v-btn>
                                    <!--//TEST-->
                                </v-card-text>
                               
                                <v-dialog v-model="dialogVisible"
                                          persistent
                                          max-width="600px">

                                    <v-card>
                                        <v-card-title class="text-h5 primary" style="font-weight:500; color:#FFFF">
                                            Registration Code
                                        </v-card-title>
                                        <v-card-subtitle class="primary pt-2" style="color:#FFFF">
                                            Please enter the security code sent to your email below
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field v-model="digitForm"
                                                                      label="Code*"
                                                                      counter="5"
                                                                      maxlength="5"
                                                                      required
                                                                      data-cy="digit-input"
                                                                      >
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            <small>*indicates required fields</small>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-actions class="primary pt-2" style="color:#FFFF">
                                            <v-spacer></v-spacer>

                                            <v-btn color="green lighten-1"
                                                   text @click="SubmitReg">
                                                Submit
                                            </v-btn>

                                            <v-btn color="red lighten-3"
                                                   text
                                                   @click="Toggledialog">
                                                Cancel
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-card>
                        </v-form>
                    </v-flex>
                </v-layout>
            </v-container>



            <v-card-actions>

            </v-card-actions>
        </v-card>

        <!--Login view-->
        <v-card color="primary" v-else height="97vh">
            <v-container fluid>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md5>


                        <v-layout align-center justify-center>
                            <v-img max-width="175" max-height="175" src="../assets/beta logo-01.svg" style="margin-bottom:5px;"></v-img>
                        </v-layout>
                        <div class="text-h5 text-md-h4 white--text text-center tec">BETA Universe Systems </div>
                        <div class="text-h5 text-md-h4 white--text text-center">Initiative Table</div>
                        <br />
                        <v-card class="elevation-12" color="#C5BBB3">

                            <v-card-title class="text-center">Login</v-card-title>
                            <v-card-subtitle>Please enter your email and password to login</v-card-subtitle>
                        </v-card>


                    </v-flex>
                </v-layout>
            </v-container>

            <v-container fluid>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md5>
                        <v-form v-model="isFormLoginValid" ref="loginform">
                            <v-card class="elevation-12" color="#C5BBB3">
                                <v-card-text>
                                    <v-text-field label="Email*"
                                                  :rules="[rules.required, rules.emailRules]"
                                                  v-model="playerLogin_Form.Email" data-cy="lemail-input"></v-text-field>
                                    <v-text-field label="Password*"
                                                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                  :rules="[rules.required, rules.min]"
                                                  :type="show1 ? 'text' : 'password'"
                                                  v-model="playerLogin_Form.Password"
                                                  @click:append="show1= !show1" data-cy="lpassword-input">
                                    </v-text-field>
                                    <small>*indicates required fields</small>
                                    <br />
                                    <v-btn :disabled="!isFormLoginValid" color="#FF4500" light @click="SubmitLogin" class="mr-2">Submit</v-btn>
                                    <v-btn color="#FF4500"  @click="toggleView" light>Register</v-btn>
                                </v-card-text>
                            </v-card>
                            </v-form>
                    </v-flex>
                </v-layout>
            </v-container>
            <v-card-actions>

            </v-card-actions>
        </v-card>

       

    </div>
  
</template>

<script>
    import Axios from 'axios';
    
    export default {
        name: 'login',
        data() {
            return {
                playerReg_Form: { PlayerID: 0, Username: "", Email: "", Password: "", CheckPassword: "",IsGM: false },
                playerLogin_Form: { Email: "", Password: ""},
                regIsActive: false,
                dialogVisible: false,
                digit: Math.floor(Math.random() * 90000) + 10000,
                digitForm: "",
                show1: false,
                isFormLoginValid: false,
                isFormRegValid: false,
                rules: {
                   required: value => !!value || 'Required.',
                   min: v => (!!v && v.length >=5)  || 'Min 5 characters',
                    emailRules: v => /.+@.+\..+/.test(v) || 'Email must be valid',
                    matchesPassword: v => (v === this.playerReg_Form.Password) || 'Passwords do not match'
                }
                
 
            }
        },
        methods: {
            validate() {
                this.$refs.form.validate()
            },
            CheckCode: function () {
                    this.digit = Math.floor(Math.random() * 90000) + 10000
                    this.SendEmail(this.playerReg_Form.Email, this.digit);
                    this.dialogVisible = !this.dialogVisible;
                
                

            },

            Toggledialog: function () {
                this.dialogVisible = !this.dialogVisible;
            },
            toggleView: function () {
                
                this.regIsActive = !this.regIsActive;

                if (this.regIsActive) {
                    //Reset forms
                    this.$refs.loginform.reset()
                    this.$refs.loginform.resetValidation()
                }

                else {
                    //Reset forms
                    this.$refs.regform.reset()
                    this.$refs.regform.resetValidation()
                }



                
            },


             //Submits reg form to database for registration
            async SubmitReg() {

                if (parseInt(this.digitForm) == this.digit) {

                    this.dialogVisible = !this.dialogVisible;
                    this.$store.commit('setLoading', true)

                    let submitForm = {
                        PlayerID: this.playerReg_Form.PlayerID,
                        Username: this.playerReg_Form.Username,
                        Email: this.playerReg_Form.Email,
                        Password: this.playerReg_Form.Password,
                        IsGM: this.playerReg_Form.IsGM
                    }

                    //let submitForm = this.playerReg_Form;

                    let player_data = null

                    //Submitt post request for a new record to web API
                    await Axios.post('api/Register', submitForm).then(response => {
                        player_data = response.data

                    }).catch(error => {
                        //If an error occurred
                        //Display an error message to the user
                        this.$swal.fire({
                            title: 'Error!',
                            text: error.response.data.msg,
                            icon: 'error'
                        });
                    });

                    if (player_data) {
                        this.SetPlayer(player_data)
                    }

                    this.$store.commit('setLoading', false)

                    this.$swal.fire({
                        title: 'Registered!',
                        text: "You have been successfully registered and logged in!",
                        icon: 'success'
                    });

                }

                else {
                    this.$swal.fire({
                        title: 'Error!',
                        text: "The code you entered was incorrect!",
                        icon: 'error'
                    });
                }

            },

            //Submits login form to database for verification
            async SubmitLogin() {

                this.$store.commit('setLoading', true);

                let submitForm = this.playerLogin_Form;
                let player_data = null

                //Submit post request for a new record to web API
                await Axios.post('api/Login', submitForm).then(response =>
                {

                    player_data = response.data
                    /*console.log(player_data);*/

                }).catch(error => {

                    //If an error occured
                    //Display an error message to the user
                    this.$swal.fire({
                        title: 'Error!',
                        text: error.response.data.msg,
                        icon: 'error'
                    });

                });

                if (player_data) {
                    this.SetPlayer(player_data)
                }

                this.$store.commit('setLoading', false);
            },

            //Sets the player/user data for the rest of the application 
            SetPlayer: function (p) {

                let playerData = { PlayerID: p.playerID, Username: p.username, Email: p.email, IsGM: p.isGM }
                this.$store.commit('setPlayer', playerData)
            },

            //This is a dummy test email function that will test email sending
            SendTestEmail: function () {

                let toEmail = "gwagenknecht@nevada.unr.edu"


                //This function sends the email
                this.SendEmail(digit, toEmail);
            },

            //This function sends the actual email
            async SendEmail(to, digit) {
                    let Email = {
                        subject: "BETA Universe Systems: Registration Code",
                        body: "<h3> Thank you for registering with the BETA Universe Systems Initiative Table Application! <br /> Below is your security code, please enter it on the registration screen. <br /> <h1 >" + digit +  "<h1 /> <br />",
                        to: to
                    }

                //Submit post request for a new record to web API
                await Axios.post('api/Email', Email).then(response => {

                    this.$swal.fire({
                        title: 'Security Code Sent!',
                        text: 'A security code  has been sent to your email',
                        icon: 'success'
                    });

                }).catch(error => {
                    this.$swal.fire({
                        title: 'Error!',
                        text: error.response.data.msg,
                        icon: 'error'
                    });
                });
            }
        },

        mounted() {
            

         

            
            
        }
    }
</script>

<style lang="scss">
    @import '../scss/styles.scss';
    .title {
        /*background-color: $test-color;*/
    }
</style>
