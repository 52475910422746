import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Login from '@/components/Login'

Vue.use(Router)

let router = new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                title: 'Login'
            }
            
        },

        {
            path: '/Login',
            name: 'Login',
            component: Login,
            meta: {
                title: 'Login'
            }

        },
        
    ]
})



export default router
