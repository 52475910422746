<template>
    <div id="character-profiles">

        <v-card color="primary" dark>
            <v-card-title class="justify-center">
                Created Characters
            </v-card-title>
            <hr />
            <v-card-actions>
                <v-btn @click="createCharacter"
                       color="#FF4500" light>
                    <v-icon light
                            left>
                        mdi-plus
                    </v-icon> Character
                </v-btn>
                <v-row justify="center">
                    <v-dialog v-model="display"
                              persistent
                              max-width="600px">
                        <v-form ref="form">
                            <v-card>
                                <v-card-title class="text-h5 primary" style="font-weight:500; color:#FFFF">
                                    Character
                                </v-card-title>
                                <v-card-subtitle class="primary pt-2" style="color:#FFFF">
                                    Please fill out the following information and click save
                                </v-card-subtitle>
                                <v-card-text>
                                    <v-container>
                                        <br />
                                        <h2>Details</h2>
                                        <v-divider></v-divider>


                                        <v-row>
                                            <!--Character Name-->
                                            <v-col cols="12">
                                                <v-text-field label="Character name*"
                                                              v-model="characterForm.name"
                                                              counter="60"
                                                              maxlength="60"
                                                              :rules="wordRules"
                                                              required
                                                              data-cy="cname-input"
                                                              ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!--Session Character belongs to-->
                                            <v-col cols="12">
                                                <v-text-field label="Group name*"
                                                              v-model="characterForm.groupname"
                                                              counter="60"
                                                              maxlength="60"
                                                              :rules="wordRules"
                                                              required
                                                              data-cy="gname-input"
                                                              ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-select :items="RollChoices"
                                                          v-model="characterForm.autoroll"
                                                          item-text="title"
                                                          item-value="value"
                                                          label="Would you like to have rolling automatically done for you?" 
                                                          data-cy="autoroll-select"
                                                          ></v-select>
                                            </v-col>
                                        </v-row>
                                        <!--IsNPC (Drop down, default PC, GM -> changeable)-->
                                        <v-row>
                                            <!--SEX-->
                                            <v-col cols="4">

                                                <v-select :items="sexChoices"
                                                          v-model="characterForm.sex"
                                                          item-text="title"
                                                          item-value="value"
                                                          label="Sex"
                                                          data-cy="sex-select"
                                                          ></v-select>

                                            </v-col>
                                            <!--SEX-->
                                            <v-col cols="4">

                                                <v-select :items="teamChoices"
                                                          v-model="characterForm.team"
                                                          item-text="title"
                                                          item-value="value"
                                                          label="Team"
                                                          data-cy="team-select"
                                                          ></v-select>

                                            </v-col>
                                            <!--SEX-->
                                            <v-col cols="4">

                                                <v-select :items="NPCChoices"
                                                          v-model="characterForm.isNPC"
                                                          item-text="title"
                                                          item-value="value"
                                                          label="NPC"
                                                          data-cy="pctype-select"
                                                          ></v-select>

                                            </v-col>


                                        </v-row>

                                        <v-row>

                                            <v-col cols="6">
                                                <v-text-field label="Action Count*"
                                                              v-model.number="characterForm.numActions"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="acnt-input"
                                                              ></v-text-field>
                                            </v-col>

                                            <v-col cols="6">
                                                <v-text-field label="Initiative Bonus*"
                                                              v-model.number="characterForm.initBonus"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="initbon-input"
                                                              ></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <br />
                                        <h2>Stats</h2>
                                        <v-divider></v-divider>
                                        <br />

                                        <v-row>
                                            <!--PER-->
                                            <v-col cols="3">
                                                <v-text-field label="PER*"
                                                              v-model.number="characterForm.per"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="per-input"></v-text-field>
                                            </v-col>
                                            <!--MD-->
                                            <v-col cols="3">
                                                <v-text-field label="MD*"
                                                              v-model.number="characterForm.md"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="md-input"
                                                              ></v-text-field>
                                            </v-col>
                                            <!--SPK-->
                                            <v-col cols="3">
                                                <v-text-field label="SPK*"
                                                              v-model.number="characterForm.spk"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="spk-input"></v-text-field>
                                            </v-col>
                                            <!--AGL-->
                                            <v-col cols="3">
                                                <v-text-field label="AGL*"
                                                              v-model.number="characterForm.agl"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="agl-input"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!--STR-->
                                            <v-col cols="3">
                                                <v-text-field label="STR*"
                                                              v-model.number="characterForm.str"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="str-input"></v-text-field>
                                            </v-col>
                                            <!--CON-->
                                            <v-col cols="3">
                                                <v-text-field label="CON*"
                                                              v-model.number="characterForm.con"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="con-input"></v-text-field>
                                            </v-col>
                                            <!--HTP-->
                                            <v-col cols="3">
                                                <v-text-field label="HTP*"
                                                              v-model.number="characterForm.htp"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="htp-input"></v-text-field>
                                            </v-col>
                                            <!--LHTP-->
                                            <v-col cols="3">
                                                <v-text-field label="LHTP*"
                                                              v-model.number="characterForm.lhtp"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="lhtp-input"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!--AIM-->
                                            <v-col cols="3">
                                                <v-text-field label="AIM*"
                                                              v-model.number="characterForm.aim"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="aim-input"></v-text-field>
                                            </v-col>
                                            <!--T.AIM-->
                                            <v-col cols="3">
                                                <v-text-field label="T.AIM*"
                                                              v-model.number="characterForm.taim"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="taim-input"></v-text-field>
                                            </v-col>
                                            <!--MOVE-->
                                            <v-col cols="3">
                                                <v-text-field label="MOVE*"
                                                              v-model.number="characterForm.move"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="move-input"></v-text-field>
                                            </v-col>
                                            <!--FLY-->
                                            <v-col cols="3">
                                                <v-text-field label="FLY*"
                                                              v-model.number="characterForm.fly"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="fly-input"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!--PAIN-->
                                            <v-col cols="3">
                                                <v-text-field label="PAIN*"
                                                              v-model.number="characterForm.pain"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="pain-input"></v-text-field>
                                            </v-col>
                                            <!--BID-->
                                            <v-col cols="3">
                                                <v-text-field label="BID*"
                                                              v-model.number="characterForm.bid"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="bid-input"></v-text-field>
                                            </v-col>
                                            <!--T.PEN-->
                                            <v-col cols="3">
                                                <v-text-field label="T.PEN*"
                                                              v-model.number="characterForm.tpen"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="tpen-input"></v-text-field>
                                            </v-col>
                                            <!--P.PEN-->
                                            <v-col cols="3">
                                                <v-text-field label="P.PEN*"
                                                              v-model.number="characterForm.ppen"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="ppen-input"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!--Cur_HTP-->
                                            <v-col cols="3">
                                                <v-text-field label="Current HTP*"
                                                              v-model.number="characterForm.cur_HTP"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="curhtp-input"></v-text-field>
                                            </v-col>
                                            <!--MEM-->
                                            <v-col cols="3">
                                                <v-text-field label="MEM*"
                                                              v-model.number="characterForm.mem"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="mem-input"></v-text-field>
                                            </v-col>
                                            <!--WIS-->
                                            <v-col cols="3">
                                                <v-text-field label="WIS*"
                                                              v-model.number="characterForm.wis"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="wis-input"></v-text-field>
                                            </v-col>
                                            <!--MS-->
                                            <v-col cols="3">
                                                <v-text-field label="MS*"
                                                              v-model.number="characterForm.ms"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="ms-input"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!--MR-->
                                            <v-col cols="3">
                                                <v-text-field label="MR*"
                                                              v-model.number="characterForm.mr"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="mr-input"></v-text-field>
                                            </v-col>
                                            <!--CHR-->
                                            <v-col cols="3">
                                                <v-text-field label="CHR*"
                                                              v-model.number="characterForm.chr"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="chr-input"></v-text-field>
                                            </v-col>
                                            <!--PB-->
                                            <v-col cols="3">
                                                <v-text-field label="PB*"
                                                              v-model.number="characterForm.pb"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="pb-input"></v-text-field>
                                            </v-col>
                                            <!--MAN-->
                                            <v-col cols="3">
                                                <v-text-field label="MAN*"
                                                              v-model.number="characterForm.man"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="man-input"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <!--RR-->
                                            <v-col cols="3">
                                                <v-text-field label="RR*"
                                                              v-model.number="characterForm.rr"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="rr-input"></v-text-field>
                                            </v-col>
                                            <!--PR-->
                                            <v-col cols="3">
                                                <v-text-field label="PR*"
                                                              v-model.number="characterForm.pr"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="pr-input"></v-text-field>
                                            </v-col>
                                            <!--SANITY-->
                                            <v-col cols="3">
                                                <v-text-field label="SANITY*"
                                                              v-model.number="characterForm.sanity"
                                                              counter="3"
                                                              type="number"
                                                              maxlength="3"
                                                              :rules="numRules"
                                                              required
                                                              data-cy="sanity-input"></v-text-field>
                                            </v-col>

                                        </v-row>


                                        <!--Character weapons-->
                                        <br />
                                        <h2>Weapons</h2>
                                        <v-divider></v-divider>
                                        <br />
                                        <div v-for="(w, index) in lstWeapons">
                                            <v-card outlined style="margin-bottom: 10px">

                                                <v-card-text>
                                                    <v-row dense>

                                                        <v-col md="1" lg="1" cols="2" class="align-self-center">
                                                            <div class=" text-h5 ">{{index + 1}})</div>
                                                        </v-col>

                                                        <v-col md="4" lg="4" cols="3">
                                                            <v-text-field label="Name" v-model="w.name" data-cy="wname-input"></v-text-field>
                                                        </v-col>


                                                        <v-col md="4" lg="4" cols="3">
                                                            <v-text-field label="WC" v-model.number="w.weaponClass" type="number" data-cy="wwc-input"></v-text-field>
                                                        </v-col>

                                                        <v-col md="3" lg="3" cols="4">
                                                            <v-text-field label="WC Bonus" v-model="w.wcBonus" data-cy="wwcbonus-input"></v-text-field>
                                                        </v-col>

                                                        

                                                    </v-row>
                                                    <v-row dense>

                                                        <v-col cols="3">
                                                            <v-select :items="isRange"
                                                                      v-model="w.isRange"
                                                                      item-text="title"
                                                                      item-value="value"
                                                                      label="Ranged?"
                                                                      data-cy="range-select">
                                                            </v-select>
                                                        </v-col>


                                                        <v-col cols="3">
                                                            <v-text-field label="Base" v-model.number="w.base" type="number" data-cy="wbase-input"></v-text-field>

                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-text-field label="Shots" v-model.number="w.shots" type="number" data-cy="wshots-input"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-text-field label="Damage" v-model="w.damage" data-cy="wdamage-input"></v-text-field>
                                                        </v-col>

                                                        <v-col cols="6">
                                                            <v-select :items="damageTypes"
                                                                      v-model="w.damageType"
                                                                      item-text="title"
                                                                      item-value="value"
                                                                      label="Damage Type"
                                                                      data-cy="wdmgtypes-select">
                                                            </v-select>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field label="Chat Message" v-model="w.chatMessage" data-cy="wchatmessage-input"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </div>

                                        <!--Character Armors-->
                                        <br />
                                        <h2>Armors</h2>
                                        <v-divider></v-divider>
                                        <br />
                                        <div v-for="(a, index) in lstArmors">
                                            <v-card outlined style="margin-bottom: 10px">

                                                <v-card-text>
                                                    <v-row dense>

                                                        <v-col md="1" lg="1" cols="2" class="align-self-center">
                                                            <div class=" text-h5 ">{{index + 1}})</div>
                                                        </v-col>

                                                        <v-col md="5" lg="5" cols="5">
                                                            <v-text-field label="Name" v-model="a.name" data-cy="aname-input"></v-text-field>
                                                        </v-col>


                                                        <v-col md="6" lg="6" cols="5">
                                                            <v-text-field label="AC" v-model.number="a.armorClass" type="number" data-cy="aac-input"></v-text-field>
                                                        </v-col>

                                                    </v-row>
                                                    <v-row dense>


                                                        <v-col cols="4">
                                                            <v-text-field label="Location" v-model="a.location" data-cy="alocation-input"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-text-field label="ASB" v-model.number="a.asb" type="number" data-cy="aasb-input"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-text-field label="RFLT" v-model.number="a.rflt" type="number" data-cy="arflt-input"></v-text-field>
                                                        </v-col>

                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </div>


                                        <!--Character Shields-->
                                        <br />
                                        <h2>Shields</h2>
                                        <v-divider></v-divider>
                                        <br />
                                        <div v-for="(s, index) in lstShields">

                                            <v-card outlined style="margin-bottom: 10px">

                                                <v-card-text>
                                                    <v-row dense>

                                                        <v-col md="1" lg="1" cols="2" class="align-self-center">
                                                            <div class=" text-h5 ">{{index + 1}})</div>
                                                        </v-col>

                                                        <v-col md="5" lg="5" cols="5">
                                                            <v-text-field label="Name" v-model="s.name" data-cy="shname-input"></v-text-field>
                                                        </v-col>


                                                        <v-col md="6" lg="6" cols="5">
                                                            <v-text-field label="AC" v-model.number="s.armorClass" type="number" data-cy="shac-input"></v-text-field>
                                                        </v-col>

                                                    </v-row>
                                                    <v-row dense>



                                                        <v-col cols="3">
                                                            <v-text-field label="Location" v-model="s.location" data-cy="shlocation-input"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-text-field label="ASB" v-model.number="s.asb" type="number" data-cy="shasb-input"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-text-field label="RFLT" v-model.number="s.rflt" type="number" data-cy="shrflt-input"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-text-field label="Percent" v-model.number="s.percent" type="number" data-cy="shper-input"></v-text-field>
                                                        </v-col>


                                                    </v-row>
                                                </v-card-text>
                                            </v-card>

                                        </div>


                                    </v-container>
                                    <small>*indicates required field</small>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions class="primary pt-2" style="color:#FFFF">
                                    <v-spacer></v-spacer>
                                    <v-btn color="green lighten-1"
                                           text
                                           @click="submitForm">
                                        Save
                                    </v-btn>

                                    <v-btn color="red lighten-3"
                                           text
                                           @click="closeForm">
                                        Cancel
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </v-row>
            </v-card-actions>

            

            <v-container fluid>
                <v-row justify="center">
                    <v-col v-for="(character, index) in characters" :key="character.id" cols="12" md="auto">
                        <v-card min-width="150px" color="rgb(197, 187, 179)" light>
                            <v-card-title class="justify-center">{{ character.name }}</v-card-title>
                            <v-card-subtitle class="text-center" v-if="character.isNPC"><i>NPC</i></v-card-subtitle>
                            <v-card-subtitle class="text-center" v-else><i>PC</i></v-card-subtitle>
                            <v-card-actions class="justify-center">
                                <v-btn @click="editCharacter(character.characterID, index)" 
                                       color="#FF4500" light>
                                    EDIT
                                </v-btn>
                                <v-btn @click="deleteCharacter(character.characterID, index)"
                                       color="#FF4500" light>
                                    DELETE
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row justify="center" v-if="characters.length == 0">

                   

                    <v-card min-width="150px" class="pa-2 ma-5" color="rgb(197, 187, 179)" light>
                        <v-card-title class="justify-center">No characters found !</v-card-title>
                        <v-card-subtitle class="text-center">Click the + button to create one !</v-card-subtitle>


                    </v-card>
                </v-row>

                <v-overlay :value="getAllLoader" :absolute="absolute">
                    <v-progress-circular indeterminate
                                         size="64">
                    </v-progress-circular>
                </v-overlay>
            </v-container>
        </v-card>
    </div>
    
</template>

<script>
    import Axios from 'axios';


    export default {
        name: 'character-profiles',
        components: {

        },
        data() {
            return {
                characters: [

                ],
                damageTypes: [
                    { title: "True", value: true },
                    { title: "False", value: false }
                ],
                isRange: [
                    { title: "Yes", value: true },
                    { title: "No", value: false }
                ],
                RollChoices: [
                    { title: "Yes", value: true },
                    { title: "No", value: false }
                ],
                sexChoices: [
                    { title: "Male", value: "M" },
                    { title: "Female", value: "F"}
                ],
                teamChoices: [
                    { title: "Us", value: "US" },
                    { title: "Them", value: "THEM" }
                ],
                NPCChoices: [
                    { title: "NPC", value: true },
                    { title: "PC", value: false }
                ],
                characterForm: {
                    characterID: 0, // should be generated server-side
                    characterID_PlayerID: this.$store.state.player.PlayerID,
                    name: "",
                    groupname: "",
                    isNPC: false,
                    per: 0,
                    md: 0,
                    spk: 0,
                    agl: 0,
                    str: 0,
                    con: 0,
                    htp: 0,
                    lhtp: 0,
                    aim: 0,
                    taim: 0,
                    move: 0,
                    fly: 0,
                    pain: 0,
                    bid: 0,
                    tpen: 0,
                    ppen: 0,
                    cur_HTP: 0,
                    mem: 0,
                    wis: 0,
                    ms: 0,
                    mr: 0,
                    chr: 0,
                    pb: 0,
                    man: 0,
                    rr: 0,
                    pr: 0,
                    sanity: 0,
                    sex: "M",
                    initBonus: 0,
                    numActions: 0,
                    autoroll: true,
                    team: "US",
                    s_del: 'f',
                    s_date: this.$moment().format("YYYY-MM-DDTHH:mm:ss.SSSS"),
                    s_create: this.$moment().format("YYYY-MM-DDTHH:mm:ss.SSSS"),
                    s_user: this.$store.state.player.Email,
                    s_createduser: this.$store.state.player.Email
                },
                lstWeapons: [{
                    weaponID: 0,
                    weaponID_CharacterID: this.$store.state.player.PlayerID,
                    name: "",
                    weaponClass: 0,
                    wcbonus: "",
                    IsRange: true,
                    base: 0,
                    shots: 0,
                    damage: "",
                    damageType: true,
                    chatMessage: ""
                },
                {
                    weaponID: 0,
                    weaponID_CharacterID: this.$store.state.player.PlayerID,
                    name: "",
                    weaponClass: 0,
                    wcbonus: "",
                    IsRange: true,
                    base: 0,
                    shots: 0,
                    damage: "",
                    damageType: true,
                    chatMessage: ""
                },
                {
                    weaponID: 0,
                    weaponID_CharacterID: this.$store.state.player.PlayerID,
                    name: "",
                    weaponClass: 0,
                    wcbonus: "",
                    IsRange: true,
                    base: 0,
                    shots: 0,
                    damage: "",
                    damageType: true,
                    chatMessage: ""
                },
                {
                    weaponID: 0,
                    weaponID_CharacterID: this.$store.state.player.PlayerID,
                    name: "",
                    weaponClass: 0,
                    wcbonus: "",
                    IsRange: true,
                    base: 0,
                    shots: 0,
                    damage: "",
                    damageType: true,
                    chatMessage: ""
                },
                {
                    weaponID: 0,
                    weaponID_CharacterID: this.$store.state.player.PlayerID,
                    name: "",
                    weaponClass: 0,
                    wcbonus: "",
                    IsRange: true,
                    base: 0,
                    shots: 0,
                    damage: "",
                    damageType: true,
                    chatMessage: ""
                }],
                lstShields: [{
                    shieldID: 0,
                    shieldID_CharacterID: this.$store.state.player.PlayerID,
                    name: "",
                    location: "",
                    armorClass: 0,
                    asb: 0,
                    rflt: 0,
                    percent: 0
                },
                {
                    shieldID: 0,
                    shieldID_CharacterID: this.$store.state.player.PlayerID,
                    name: "",
                    location: "",
                    armorClass: 0,
                    asb: 0,
                    rflt: 0,
                    percent: 0
                }],
                lstArmors: [{
                    armorID: 0,
                    armorID_CharacterID: this.$store.state.player.PlayerID,
                    name: "",
                    location: "",
                    armorClass: 0,
                    asb: 0,
                    rflt: 0
                },
                {
                    armorID: 0,
                    armorID_CharacterID: this.$store.state.player.PlayerID,
                    name: "",
                    location: "",
                    armorClass: 0,
                    asb: 0,
                    rflt: 0
                }],
                IsGM: this.$store.state.player.IsGM,
                absolute: true,
                getAllLoader: false,
                offset: true,
                display: false,
                wordRules: [v => (v.length >= 1 && v.length <= 60) || 'Between 1 and 60 characters'],
                numRules: [v => (v >= 0 && v <= 999) || 'Between 1 and  3 whole numbers'],
            }
        },
        methods: {
            confirmSwal() {
                return this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                });
            },

            successSwal(text) {
                this.$swal.fire({
                    title: 'Success!',
                    text: text,
                    icon: 'success'
                });
            },

            failSwal(text) {
                this.$swal.fire({
                    title: 'Error!',
                    text: text,
                    icon: 'error'
                });
            },

            // Creates a new character
            async createCharacter() {
                // Show the modal
                this.display = true;
            },

            closeForm() {
                
                this.defaultValues();


                this.display = false;
                this.$refs.form.resetValidation()
            },

            defaultValues() {
                this.characterForm = {
                    characterID: 0, // should be generated server-side
                    characterID_PlayerID: this.$store.state.player.PlayerID,
                    name: "",
                    groupname: "",
                    isNPC: false,
                    per: 0,
                    md: 0,
                    spk: 0,
                    agl: 0,
                    str: 0,
                    con: 0,
                    htp: 0,
                    lhtp: 0,
                    aim: 0,
                    taim: 0,
                    move: 0,
                    fly: 0,
                    pain: 0,
                    bid: 0,
                    tpen: 0,
                    ppen: 0,
                    cur_HTP: 0,
                    mem: 0,
                    wis: 0,
                    ms: 0,
                    mr: 0,
                    chr: 0,
                    pb: 0,
                    man: 0,
                    rr: 0,
                    pr: 0,
                    sanity: 0,
                    sex: "M",
                    initBonus: 0,
                    numActions: 0,
                    autoroll: true,
                    team: "US",
                    s_del: 'f',
                    s_date: this.$moment().format("YYYY-MM-DDTHH:mm:ss.SSSS"),
                    s_create: this.$moment().format("YYYY-MM-DDTHH:mm:ss.SSSS"),
                    s_user: this.$store.state.player.Email,
                    s_createduser: this.$store.state.player.Email
                };

                this.lstWeapons = [{
                    ID: 0,
                    characterID: this.$store.state.player.PlayerID,
                    name: "",
                    weaponClass: 0,
                    WCBonus: "",
                    IsRange: true,
                    Base: 0,
                    Shots: 0,
                    Damage: "",
                    DamageType: true,
                    chatMessage: ""
                },

                {
                    ID: 0,
                    characterID: this.$store.state.player.PlayerID,
                    name: "",
                    weaponClass: 0,
                    WCBonus: "",
                    IsRange: true,
                    Base: 0,
                    Shots: 0,
                    Damage: "",
                    DamageType: true,
                    chatMessage: ""
                },

                {
                    ID: 0,
                    characterID: this.$store.state.player.PlayerID,
                    name: "",
                    weaponClass: 0,
                    WCBonus: "",
                    IsRange: true,
                    Base: 0,
                    Shots: 0,
                    Damage: "",
                    DamageType: true,
                    chatMessage: ""
                },

                {
                    ID: 0,
                    characterID: this.$store.state.player.PlayerID,
                    name: "",
                    weaponClass: 0,
                    WCBonus: "",
                    IsRange: true,
                    Base: 0,
                    Shots: 0,
                    Damage: "",
                    DamageType: true,
                    chatMessage: ""
                },

                {
                    ID: 0,
                    characterID: this.$store.state.player.PlayerID,
                    name: "",
                    weaponClass: 0,
                    WCBonus: "",
                    IsRange: true,
                    Base: 0,
                    Shots: 0,
                    Damage: "",
                    DamageType: true,
                    chatMessage: ""
                }];

                this.lstShields = [{
                    ID: 0,
                    characterID: this.$store.state.player.PlayerID,
                    name: "",
                    location: "",
                    AC: 0,
                    ASB: 0,
                    RFLT: 0,
                    percent: 0
                },
                {
                    ID: 0,
                    characterID: this.$store.state.player.PlayerID,
                    name: "",
                    location: "",
                    AC: 0,
                    ASB: 0,
                    RFLT: 0,
                    percent: 0
                }];

                this.lstArmors = [{
                    ID: 0,
                    characterID: this.$store.state.player.PlayerID,
                    name: "",
                    location: "",
                    AC: 0,
                    ASB: 0,
                    RFLT: 0
                },
                {
                    ID: 0,
                    characterID: this.$store.state.player.PlayerID,
                    name: "",
                    location: "",
                    AC: 0,
                    ASB: 0,
                    RFLT: 0
                }];
            },

            async submitForm() {

             

                // Don't send over the data if the character name is blank?
               if(!this.$refs.form.validate()) return;

                let CharLoadout = {
                    character: this.characterForm,
                    weapons: this.lstWeapons,
                    armors: this.lstArmors,
                    shields: this.lstShields
                };

                // Display loading wheel
                this.$store.commit('setLoading', true);

                if (CharLoadout.character.characterID == 0) {

                    await Axios.post('api/Loadout', CharLoadout).then(response => {

                        if (response.status == 200) {

                            this.defaultValues();

                            this.characters = [];
                            this.getAllCharacters();

                            this.display = false;


                            //Sweetalert for fun
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Adding',
                                text: "The character was saved!"
                            });
                        }
                        else {

                            //Display an error message to the user
                            this.failSwal('An error occurred while adding the character.');;
                        }
                    }).catch(response => {
                        //If an error occured
                        //Display an error message to the user
                        this.failSwal('An error occurred while adding the character.');;
                    });


                    // Close form
                    this.closeForm();
                    // Remove loading wheel
                    this.$store.commit('setLoading', false);
                }
                else {
                    await Axios.put('api/Loadout/' + CharLoadout.character.characterID, CharLoadout).then(response => {

                        if (response.status == 200) {

                            this.defaultValues();

                            this.characters = [];
                            this.getAllCharacters();

                            this.display = false;


                            //Sweetalert for fun
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Editing',
                                text: "The character was saved!"
                            });
                        }
                        else {

                            //Display an error message to the user
                            this.failSwal('An error occurred while editing the character.');;
                        }
                    }).catch(response => {
                        //If an error occured
                        //Display an error message to the user
                        this.failSwal('An error occurred while editing the character.');;
                    });


                    // Close form
                    this.closeForm();
                    // Remove loading wheel
                    this.$store.commit('setLoading', false);
                }

                

                
            },

            async editCharacter(id) {
                //Need to define this instance for the axios request
                var vm = this;

                //Retrieve the record we want to edit given the ID from the button selection
                Axios.get('api/Loadout/' + id).then(response => {

                    //If our response was succesful
                    if (response.status === 200) {

                       
                        //Map the data our fields to the data we got
                        this.characterForm = response.data.character;
                        this.lstWeapons = response.data.weapons;
                        this.lstArmors = response.data.armors;
                        this.lstShields = response.data.shields;


                        //Open the form
                        this.display = true;
                    }
                    //If an error occured
                    else {

                        //Display an error message to the user
                        this.failSwal('An error occurred while editing the character.');;
                    }
                }).catch(response => {
                    //If an error occured
                    //Display an error message to the user
                    this.failSwal('An error occurred while editing the character.');;
                });
            },

            // Delete an existing character
            async deleteCharacter(id, index) {
                console.log('Deleting character: ' + id);

                // Present swal; ask for confirmation before deleting
                let confirmation = await this.confirmSwal();
                // {isConfirmed: false, isDenied: false, isDismissed: true, dismiss: 'cancel'}
                console.log(confirmation);
                if (confirmation.isConfirmed !== true) {
                    return;
                }
                
                // Don't need to search when you can just pass the index
                let selected = this.characters[index];

                // "Delete" our item by setting its flag
                selected.s_del = 't';

                // Update metadata 
                selected.s_user = this.$store.state.player.Email;

                // Display loading wheel
                this.$store.commit('setLoading', true);

                // Make delete request to api
                let vm = this;
                await Axios.put('api/Character/' + id, selected)
                    .then(response => {
                        if (response.status == 200) {
                            // Delete the session from local storage
                            vm.characters.splice(index,1);
                        } else {
                            this.failSwal('An error occurred while deleting the character.');;
                        }
                        this.successSwal(selected.name + ' was succesfully deleted.');
                    })
                    .catch(error => {
                        console.log(error);
                        this.failSwal('An error occurred while deleting the character.');
                    });

                // Remove loading wheel
                this.$store.commit('setLoading', false);
            },

            async getAllCharacters() {
                // Keep a reference to this component to access data
                this.getAllLoader = true;

                let vm = this;
                await Axios.get('api/GetPlayerCharacters/' + this.$store.state.player.PlayerID)
                    .then(response => {
                        vm.characters = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
                
                // Stop loading wheel
                this.getAllLoader = false;
            }
        },

        mounted() {
            

            // Grab all the sessions for rendering
            this.getAllCharacters();
        }
    }
</script>

<style lang="scss">
    @import '../scss/styles.scss';
    .title {
        /*background-color: $test-color;*/
    }
</style>
