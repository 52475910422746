<!--
    TODO:
    [X] Validate input (disable submit/show errors if input is bad)
    [X] Show create/delete buttons only if the player.IsGM flag is set
    [X] Test deletion
    [X] Put sessions into a container for better formatting
    [ ] Joining
    [ ] Cache empty fields before submitting?
    [X] Add spinning wheel during network calls?
-->


<template>
     <div id="session-manage">

         <!--Container for all the session cards-->
         <v-card color="primary" dark>
             <v-card-title class="justify-center">
                 Available Sessions
             </v-card-title>
             <hr />
             <v-card-actions>
                 
                 <!--Copied dialog from vuetify docs-->
                 <!--Only show if user is GM-->
                 <!--Throw up a form when creating a new session-->
                 <!-- https://vuetifyjs.com/en/components/dialogs/#form -->
                 <v-btn @click="createSession"
                        v-show="IsGM"
                        color="#FBC02D" light>
                     <v-icon dark
                             left>
                         mdi-plus
                     </v-icon>SESSION
                 </v-btn>
                 <v-row justify="center" v-show="IsGM">
                     <v-dialog v-model="sessionForm.display"
                               persistent
                               max-width="600px">
                         
    
                         <!--Don't think this is necessary-->
                         <!--
                             <template v-slot:activator="{ on, attrs }">
                                 <v-btn color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on">
                                     Open Dialog
                                 </v-btn>
                             </template>
                          -->
                         <v-form ref="form">
                             <v-card>
                                 <v-card-title class="text-h5 primary" style="font-weight:500; color:#FFFF">
                                     Create Session
                                 </v-card-title>
                                 <v-card-subtitle class="primary pt-2" style="color:#FFFF">
                                     Please fill out the following form and click save
                                 </v-card-subtitle>
                                 <v-card-text>
                                     <v-container>
                                         <v-row>
                                             <v-col cols="12">
                                                 <v-text-field label="Session name*"
                                                               v-model="sessionForm.Name"
                                                               counter="60"
                                                               maxlength="60"
                                                               :rules="sessionForm.rules"
                                                               required
                                                               data-cy="sessionname-input"
                                                               ></v-text-field>
                                             </v-col>
                                             <v-col cols="12">
                                                 <v-text-field label="Campaign name*"
                                                               v-model="sessionForm.Campaign"
                                                               counter="60"
                                                               maxlength="60"
                                                               :rules="sessionForm.rules"
                                                               required
                                                               data-cy="sessionname-input"
                                                               ></v-text-field>
                                             </v-col>
                                         </v-row>
                                     </v-container>
                                     <small>*indicates required field</small>
                                 </v-card-text>
                                 <v-divider></v-divider>
                                 <v-card-actions class="primary pt-2" style="color:#FFFF">
                                     <v-spacer></v-spacer>

                                     <v-btn color="green lighten-1" 
                                            text
                                            @click="submitForm" >
                                         Save
                                     </v-btn>

                                     <v-btn color="red lighten-3"
                                            text
                                            @click="closeForm" >
                                         Cancel
                                     </v-btn>

                                 </v-card-actions>
                             </v-card>
                         </v-form>
                     </v-dialog>
                 </v-row>
             </v-card-actions>

             <!--Template for each session (use a for-loop)-->
             <!--Also see: https://vuetifyjs.com/en/components/cards/#grids -->
             <!--And: https://vuetifyjs.com/en/components/grids/ -->
             <!--For more on using grids for layout (not doing this rn)-->
             <!--See the session-internal component for usage-->
             <v-container fluid>
                 <v-row justify="center">
                     <v-col v-for="(session, index) in sessions" :key="session.id" cols="12" md="auto">
                         <v-card min-width="150px" color="rgb(197, 187, 179)" light>
                             <v-card-title class="justify-center">{{ session.name }}</v-card-title>
                             <v-card-subtitle class="text-center"><i>{{ session.campaign }}</i></v-card-subtitle>
                             <v-card-actions class="justify-center">
                                 <v-btn @click="joinSession(session, index)"
                                        color="#FF4500" light
                                        >
                                    JOIN
                                 </v-btn>
                                 <v-btn @click="deleteSession(session.sessionID, index)" 
                                        v-show="IsGM"
                                        color="#FBC02D" light>
                                    DELETE
                                 </v-btn>
                             </v-card-actions>
                         </v-card>
                     </v-col>
                 </v-row>

                 <!--Loader-->
                 <v-overlay :value="getAllLoader" :absolute="absolute">
                     <v-progress-circular indeterminate
                                          size="64">
                     </v-progress-circular>
                 </v-overlay>
             </v-container>
         </v-card>



     </div>

</template>

<script>
    import Axios from 'axios';
    export default {
        name: 'session-manage',
        // add PlayerID, Usename, and IsGM as props
        data() {
            // Return an array of objects
            return {
                sessions: [
                    
                ],
                sessionForm: {
                    valid: true,
                    display: false,
                    Name: '',
                    Campaign: '',
                    rules: [v => (v.length >= 1 && v.length <= 60) || 'Between 1 and 60 characters'],
                },
                IsGM: this.$store.state.player.IsGM,
                absolute: true,
                getAllLoader: false,
            }
        },
        methods: {
            confirmSwal() {
                return this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                });
            },

            successSwal(text) {
                this.$swal.fire({
                    title: 'Success!',
                    text: text,
                    icon: 'success'
                });
            },

            failSwal(text) {
                this.$swal.fire({
                    title: 'Error!',
                    text: text,
                    icon: 'error'
                });
            },

            // Start new session process
            createSession() {
                // Display the dialog
                this.sessionForm.display = true;
            },

            closeForm() {
                this.sessionForm.display = false;
                this.sessionForm.Name = '';
                this.sessionForm.Campaign = '';
                this.$refs.form.resetValidation()
             
            },

            // This is where the actual network call for creating is done
            async submitForm() {
                console.log('Creating new session...');

                // Don't send over the data if the session name is blank?
                if (!this.$refs.form.validate()) return;

                // Grab form data and generate the new session
                // PlayerID and Username is needed for metadata purposes
                let newSession = {
                    sessionID: 0, // should be generated server-side
                    name: this.sessionForm.Name,
                    campaign: this.sessionForm.Campaign,
                    isJoinable: true,
                    s_del: 'f',
                    s_date: this.$moment().format("YYYY-MM-DDTHH:mm:ss.SSSS"),
                    s_create: this.$moment().format("YYYY-MM-DDTHH:mm:ss.SSSS"),
                    s_user: this.$store.state.player.Email,
                    s_createduser: this.$store.state.player.Email,
                };

                // Display loading wheel
                this.$store.commit('setLoading', true);

                // Send data over
                let vm = this;
                await Axios.post('api/Sessions', newSession)
                    .then(response => {
                        vm.sessions.push(response.data);

                        //Sweetalert for fun
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Saving',
                            text: "The session was saved!"
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });

                // Remove loading wheel
                this.$store.commit('setLoading', false);

                // Display swal
                // (code here)

                // Close form
                this.closeForm();
            },

            // Join an existing session
            joinSession(session) {

                //First we need to make sure we can join the current session
                //Needs to be checked from the DB here since the session can be closed at any point 
                //If not we cannot trigger the emit (starts the connection loop)


                this.$emit('JoinSession', session)

               
            },

            // Delete an existing session
            async deleteSession(id, index) {
                console.log('Deleting session: ' + id);

                // Present swal; ask for confirmation before deleting
                let confirmation = await this.confirmSwal();
                // {isConfirmed: false, isDenied: false, isDismissed: true, dismiss: 'cancel'}
                console.log(confirmation);
                if (confirmation.isConfirmed !== true) {
                    return;
                }

                // Don't need to search when you can just pass the index
                let selected = this.sessions[index];
                
                // "Delete" our item by setting its flag
                selected.s_del = 't';

                // Update metadata 
                selected.s_user = this.$store.state.player.Email;

                // Display loading wheel
                this.$store.commit('setLoading', true);

                // Make delete request to api
                let vm = this;
                await Axios.put('api/Sessions/' + id, selected)
                    .then(response => {
                        if (response.status == 200) {
                            // Delete the session from local storage
                            vm.sessions.splice(index, 1);
                        } else {
                            console.log(response);
                        }
                        this.successSwal(selected.name + ' was succesfully deleted.');
                    })
                    .catch(error => {
                        console.log(error);
                        this.failSwal('An error occurred while deleting the session.');
                    });

                // Remove loading wheel
                this.$store.commit('setLoading', false);

                // Show completion swal
                // (see Axios call)
            },

            // Grab all sessions
            // Only grabbing non-deleted sessions
            async getAllSessions() {
                // Keep a reference to this component to access data
                let vm = this;
                await Axios.get('api/Sessions')
                    .then(response => {
                        // The server will filter the sessions before sending them, 
                        // so we don't need to check for the 'S_del' flag here 
                        vm.sessions = vm.sessions.concat(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });

                // Stop loading wheel
                this.getAllLoader = false;
            }
        },

        mounted() {
            // Show loading wheel
            this.getAllLoader = true;

            // Grab all the sessions for rendering
            this.getAllSessions();
        }
    }
</script>

<style lang="scss">
    @import '../scss/styles.scss';
    .title {
        /*background-color: $test-color;*/
    }
</style>
