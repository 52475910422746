import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
        player: null,
        loading: false,
        inSession: false,
  },
  mutations: {

    setPlayer(state, payload) {
        state.player = payload
      },

      setLoading(state, payload) {
          state.loading = payload
      },

      setinSession(state, payload) {
          state.inSession = payload
      },

  },
  actions: {
  },
  modules: {
  }
})
