<template>
    <v-app>
        <v-app-bar app color="primary" dark dense v-if="!isLogin">
            <v-spacer></v-spacer>
            <v-img max-width="50" max-height="50" src="./assets/beta logo-01.svg" style="margin-right:5px;"></v-img>
            <v-toolbar-title>BETA Universe Systems</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

        </v-app-bar>
      

        <v-navigation-drawer v-model="drawer" dark
                             fixed
                             temporary right v-if="!isLogin" color="primary">
            <v-list nav
                    dense>
                <v-list-item-group v-model="group">
                    <v-list-item dark>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6">
                                {{this.$store.state.player.Username}}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{this.$store.state.player.Email}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="drawer = false" dark>
                        <v-list-item-icon>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="Logout" dark>
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <v-container>
                <router-view />
            </v-container>
        </v-main>

        <v-overlay :value="isLoading" z-index="999">
            <v-progress-circular indeterminate
                                 size="64">
            </v-progress-circular>
        </v-overlay>

        <!--<v-footer app padless>

    </v-footer>-->
    </v-app>
</template>

<style>
    .swal2-container {
        font-family: "Roboto", sans-serif;
    }
</style>

<script>
    export default {
        name: 'App',
        data() {
            return {
                drawer: false,
                group: null,
            }
        },

        methods: {
    
            Logout() {

                if (this.$store.state.inSession == true) {
                    this.drawer = false;
                }

                else {
                    this.drawer = false;
                    this.$store.commit('setPlayer', null)
                }


            }
        },

        computed: {
            isLogin() {
                return this.$route.name === 'Login'
            },

            isLoading() {
                if (this.$store.state.loading == true) {
                    return true;
                }

                else {
                    return false;
                }
            }
        },

        created() {
            if (this.$store.state.player == null) {
                
                if(this.$route.name != 'Login')
                    this.$router.push('/Login');
            }
        },

        watch: {
            $route(to, from) {

                if (this.$store.state.player == null && to.name == "Home") {
                    this.$router.push('/Login');
                }

            },

            '$store.state.player': function () {
                if (this.$store.state.player == null) {
                    this.$router.push('/Login');
                }

                else {
                    this.$router.push('/');
                    console.log("This person is logged in : ", this.$store.state.player)
                }
            }
        }
    }
</script>
