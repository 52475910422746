<template>
    <div id="home">

        

        <!--When we have joined a session-->
        <div v-show="JoinedSession">
            <sessionInternal ref="ISessionComp" @LeftSession="LeaveSession"></sessionInternal>
        </div>

        <!--When we are not in a session-->
        <div v-show="!JoinedSession">

            <!--Session manager component-->
            <sessionManager @JoinSession="PrepareSessionView"></sessionManager>

            <br />
            <!--Character profiles component-->
            <characters></characters>

            <!--Dialog when joining a session-->
            <v-dialog v-model="selectCharacterDialog"
                      width="500" persistent style="max-height:70%">

                <v-card>
                    <v-card-title class="text-h5 primary" style="font-weight:500; color:#FFFF">
                        Character Selection
                    </v-card-title>

                    <v-card-subtitle class="primary pt-2" style="color:#FFFF">
                        Please click on a character from your list below
                    </v-card-subtitle>

                    <v-card-text>
                        <v-list>
                            <v-list-item-group v-model="selectedCharacters" :multiple="IsGM">
                                <v-text-field label="Filter characters via group name"
                                              v-model="characterSearch"
                                             ></v-text-field>
                                <v-list-item v-for="(item, i) in filteredcharacter_list"
                                             :key="item.characterID" :value="item.characterID" style="margin-bottom:2px;">

                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="item.groupname"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions class="primary pt-2" style="color:#FFFF">
                        <v-spacer></v-spacer>
                        <v-btn color="green lighten-1"
                               text 
                               @click="FinalizeJoining">
                            Join
                        </v-btn>

                        <v-btn color="red lighten-3"
                               text
                               @click="CancelJoining">
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>

        </div>
    </div>

</template>

<script>

    import sessionManager from './session-manage.vue'
    import characters from './character-profiles.vue'
    import sessionInternal from './session-internal.vue'
    import Axios from 'axios';
    import { DiceRoll } from '@dice-roller/rpg-dice-roller';

    export default {
        name: 'home',
        components: {
            sessionManager,
            characters,
            sessionInternal
        },
        data() {
            return {
                date: "",
                JoinedSession: false,
                characterSearch: "",
                character_list: [],
                selectCharacterDialog: false,
                selectedCharacters: [],
                selectedSession: null,
                IsGM: this.$store.state.player.IsGM,
                ex_roll: 0,
            }
        },
        methods: {
            ShowSwal() {
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    }
                })
            },
            changeView() {
                this.JoinedSession = !this.JoinedSession;
            },
            LeaveSession() {

                this.changeView();
                this.selectedCharacters = [];
                this.selectedSession = null;
            },
            async PrepareSessionView(session) {

                this.selectedSession = session;
                this.characterSearch = ""

                //Let's get an up to date list of characters
                await this.UpdateCharacterList();

                //If the player has no characters they cannot join
                if (this.character_list.length == 0) {

                    this.$swal.fire({
                        title: 'Error',
                        text: "No characters were found for this player",
                        icon: 'error',
                    });
                }

                else
                {
                    //Need to display a differnt character selection screen if GM
                    this.selectCharacterDialog = true;
                    
                }

                

                
                
            },
            FinalizeJoining() {

                if (this.selectedCharacters.length == 0) {

                    this.$swal.fire({
                        title: 'Error',
                        text: "Please select a valid character",
                        icon: 'error',
                    });
                }

                else {

                    //Since a non-GM can only select one char, convert to array
                    if (!this.IsGM) {
                        let char = this.selectedCharacters;
                        this.selectedCharacters = [];
                        this.selectedCharacters.push(char)
                    }


                    this.selectCharacterDialog = false;
                    this.changeView();
                    this.$refs.ISessionComp.EstablishSession(this.selectedSession, this.selectedCharacters);
                }
            },
            CancelJoining() {
                this.selectCharacterDialog = false;
                this.selectedCharacters = [];
                this.characterSearch = ""
            },
            async UpdateCharacterList() {

                this.$store.commit('setLoading', true);

                let vm = this;
                await Axios.get('api/GetPlayerCharacters/' + this.$store.state.player.PlayerID)
                    .then(response => {                      
                        vm.character_list = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });

                this.$store.commit('setLoading', false);
            },

            
        },
        computed: {
            filteredcharacter_list: function () {
                return this.character_list.filter((character) => {
                    return character.groupname.toLowerCase().match(this.characterSearch)
                });
            }
        },
        mounted() {
            

          this.date = this.$moment().format("LLLL");

            
            
        }
    }
</script>

<style lang="scss">
    @import '../scss/styles.scss';
    .title {
        /*background-color: $test-color;*/
    }
</style>
